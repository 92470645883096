import React from 'react'
import styles from './JobUpload.module.scss'
import ArtistNavbar from '../navbar/ArtistNavbar'
import Common from '../header/Common'
import UploadHeader from './UploadHeader'

const JobUpload = () => {
  return (
    <div className={styles.container}>
    <div className={styles.navSection}>
   <ArtistNavbar />
 </div>

 <div className={styles.bodySection}>
   <div className={styles.section1}>
     <Common />
   </div>
   <div className={styles.section2}>
     <UploadHeader/>
</div>
</div>

</div>
  )
}

export default JobUpload