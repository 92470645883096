import React from "react";
import styles from "./styles.module.scss";
import Text from "../text/Text";

function Statistics({ label, value, icon }) {
  return (
    <div className={styles.container}>
      {icon}
      <div className={styles.row}>
        <Text color="#7C6E90">{label}</Text>
        <Text fontFamily="Urbanist" color="#311057" fontSize="24px">
          {value}
        </Text>
      </div>
    </div>
  );
}

export default Statistics;
