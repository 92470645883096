import React from "react";
import CommonTemplate from "../../../Templates/dashboard/CommonTemplate";
import ListControlMolecule from "../../../Molecules/list-controls/ListJobsControlMolecule";

const ListControls = () => {
  return (
    <>
      <CommonTemplate>
        <ListControlMolecule />
      </CommonTemplate>
    </>
  );
};

export default ListControls;
