import React from 'react'
import styles from './ComplianceContainer.module.scss'

const ComplianceContainer = () => {
  return (
    <div className={styles.container}>
        <div className={styles.headerSection}>
            <img src="/assets/icons/backArrow.png" alt="backArrow" className={styles.image}/>
            <h1 className={styles.heading}>Start Compliance Check</h1>
        </div>
        <div className={styles.mainSection}>
          <div className={styles.section1}>
            <div className={styles.subSection1}>
           <p>Partner ID</p>
           <input type="text" />
           </div>
            <div className={styles.subSection2}>
           <p>Compliance Check Started</p>
           <input type="text" />
           </div>
            <div className={styles.subSection3}>
           <p>Time Duration</p>
           <select name="" id="">
            <option value="">1:00:00</option>
            <option value="">2:00:00</option>
           </select>
           </div>
          </div>
          <div className={styles.section2}>
          <div className={styles.subSection1}>
           <p>Preferred Level</p>
           <input type="text" />
           </div>
           <div className={styles.subSection2}>
           <p>Compliance Check</p>
           <input type="text" />
           </div>

           <div className={styles.subSection3}>
           <p>Reference Images</p>
           
           <div className={styles.imageSection}>
             
             <img src="/assets/graphics/watch1.jpg" alt="watch" />
             <img src="/assets/graphics/watch1.jpg" alt="watch" />
             <img src="/assets/graphics/watch1.jpg" alt="watch" />
             <div className={styles.overlay}>
    <img src="/assets/graphics/watch1.jpg" alt="watch" />
    <div className={styles.overlayText}>+2</div>
    </div>
          
           

           </div>
           </div>
          </div>
          <div className={styles.section3}>
            <p>Notes</p>
            <textarea name="" id="" placeholder='Add your notes here...'></textarea>
          </div>
          <div className={styles.section4}>
          <hr />

            <button className={styles.secondaryButton}>Cancel</button>
            <button className={styles.startButton}>Assign Job</button>

          </div>
        </div>
    </div>
  )
}

export default ComplianceContainer