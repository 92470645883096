import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import IqaDashboard from "./Templates/dashboard/IqaDashboard";
import HomePage from "./Pages/designlead/HomePage";
import ManageEmployees from "./Pages/manage_employees/ManageEmployees";
import LeaderboardPage from "./Pages/common/LeaderboardPage";
import PostInvalidFeedbacks from "./organisms/postInvalids/PostInvalidFeedbacks";
import InvalidFeedbacks from "./organisms/invalidfeedbacks/InvalidFeedbacks";
import LoginPage from "./organisms/loginpage/LoginPage";
// import CommonHeader from "./Molecules/header/commonheader/CommonHeader";
// import Navmenu from "./Atoms/button/Navmenu";
// import ArtistNavbar from "./Molecules/navbar/ArtistNavbar";
import ArtistHomepage from "./organisms/homepage/ArtistHomepage";
import HourlyUpdatePage from "./Pages/common/HourlyUpdatePage";
import JobBoard from "./Molecules/jobboard/JobBoard";
import LeaderBoard from "./Molecules/leaderboard/LeaderBoard";
import Support from "./Molecules/support/Support";
import JobUpload from "./Molecules/jobuploader/JobUpload";
import AssignNewJob from "./Molecules/assignNewJob/AssignNewJob";
import FileAssign from "./Molecules/fileAssign/FileAssign";
import ComplianceCheck from "./Molecules/complianceCheck/ComplianceCheck";
import JobAssigner from "./Molecules/jobAssigner/JobAssigner";
import RevisionComplianceCheck from "./Molecules/revisionComplianceCheck/RevisionComplianceCheck";
import Unauthorized from "./Molecules/unauthorized/Unauthorized";
import RevisionComplianceCheckPage from "./Molecules/revisionComplianceCheck/RevisionComplianceCheckPage";
import ItTaskboard from "./organisms/it-taskboard/ItTaskboard";
import FileUploader from "./Pages/common/FileUploader";
import FileAssignerPage from "./organisms/common-pages/fileassigner/FileAssignerPage";
import CCheckPage from "./organisms/common-pages/compliancecheck/CCheckPage";
import YtaJobsList from "./organisms/common-pages/list-jobs/ccheck-list-jobs/YtaJobsList";
import RevisionCCjobsList from "./organisms/common-pages/list-jobs/ccheck-list-jobs/RevisionCCjobsList";
import ReassignJobsList from "./organisms/common-pages/list-jobs/ccheck-list-jobs/ReassignJobsList";
import ListControls from "./organisms/common-pages/list-controls/ListControls";
import EqapdfReport from "./organisms/reports/eqa/EqapdfReport";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />}></Route>

        <Route path="/artist/home" element={<ArtistHomepage />}></Route>
        <Route path="/hourly-updater" element={<HourlyUpdatePage />}></Route>

        <Route path="/iqa" element={<IqaDashboard />}></Route>
        <Route path="/design-lead" element={<HomePage />}></Route>
        <Route path="/manage-employees" element={<ManageEmployees />}></Route>
        <Route path="/leaderboards" element={<LeaderboardPage />}></Route>
        <Route path="/job-boards" element={<JobBoard />}></Route>
        <Route
          path="/reports/eqa-pdf-report"
          element={<EqapdfReport />}
        ></Route>
        <Route
          path="/start-compliance-check"
          element={<ComplianceCheck />}
        ></Route>
        <Route
          path="/revision-cc/:partnerId"
          element={<RevisionComplianceCheck />}
        ></Route>
        <Route
          path="/list-jobs/revision-ccheck"
          element={<RevisionComplianceCheckPage />}
        ></Route>

        <Route path="/common/list-jobs/cc" element={<CCheckPage />}></Route>

        <Route path="/home" element={<ListControls />}></Route>

        <Route path="/common/list-jobs/yta" element={<YtaJobsList />}></Route>
        <Route
          path="/common/list-jobs/revision-cc"
          element={<RevisionCCjobsList />}
        ></Route>

        <Route
          path="/common/list-jobs/hold"
          element={<ReassignJobsList />}
        ></Route>
        <Route path="/job-assigner" element={<JobAssigner />}></Route>
        <Route path="/job-uploads" element={<JobUpload />}></Route>
        <Route path="/artist/leaderboard" element={<LeaderBoard />}></Route>
        <Route path="/artist/support" element={<Support />}></Route>
        <Route path="/unauthorized" element={<Unauthorized />}></Route>
        <Route path="/job-uploader" element={<FileUploader />}></Route>
        <Route path="/it-taskboard" element={<ItTaskboard />}></Route>
        <Route
          path="/common/file-assigner"
          element={<FileAssignerPage />}
        ></Route>

        <Route path="/common/file-assigner" element={<CCheckPage />}></Route>

        <Route
          path="/invalidfeedbacks/form"
          element={<PostInvalidFeedbacks />}
        ></Route>
        <Route path="/invalidfeedbacks" element={<InvalidFeedbacks />}></Route>
        <Route path="/artist/fileAssign" element={<FileAssign />}></Route>
        <Route path="/dashboard/assignjob" element={<AssignNewJob />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
