import React from 'react'

import styles from './JobAssigner.module.scss'
import Common from '../header/Common'

import JobAssignContainer from './JobAssignContainer'
import ArtistNavbar from '../assignNewJob/ArtistNavbar'

const JobAssigner = () => {
  return (
    <div className={styles.container}>
    <div className={styles.navSection}>
   <ArtistNavbar />
 </div>

 <div className={styles.bodySection}>
   <div className={styles.section1}>
     <Common />
   </div>
   <div className={styles.section2}>
   <JobAssignContainer/>
</div>

<div className={styles.footer}>
   <p>Copyrights 2024 @Advertflair Pvt Ltd</p>
</div>
</div>
</div>
  )
}

export default JobAssigner