import React from "react";
import DesignLeadDashboard from "../../Templates/dashboard/DesignLeadDashboard";
import CommonHeader from "../../Molecules/header/commonheader/CommonHeader";
import EmployeeStatus from "../../Molecules/widgets/employee_status/EmployeeStatus";
import LeaveApprovals from "../../Molecules/widgets/leave_approvals/LeaveApprovals";
import styles from "./styles.module.scss";
import EmployeeTable from "../../Molecules/table/employeeTable/EmployeeTable";
import DesignLeadNavbar from "../../Molecules/navbar/DesignLeadNavbar";

function ManageEmployees() {
  return (
    <DesignLeadDashboard navbar={<DesignLeadNavbar />}>
      <CommonHeader />
      <div className={styles.container}>
        <EmployeeStatus />
        <LeaveApprovals />
      </div>

      <EmployeeTable />
    </DesignLeadDashboard>
  );
}

export default ManageEmployees;
