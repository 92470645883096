import React from "react";
import styles from "./styles.module.scss";
import Text from "../../Atoms/text/Text";
import Button from "../../Atoms/button/Button";
import IconButton from "../../Atoms/iconbutton/IconButton";
import { ArrowForward } from "@mui/icons-material";

function DesignLeadAction() {
  return (
    <div className={styles.container}>
      <Text color="#B8B4D2" fontSize="12px">
        Your Action Required
      </Text>

      <Text
        fontSize="22px"
        fontFamily="Urbanist"
        color="white"
        fontWeight="700"
      >
        You have 20 jobs yet to compliance check
      </Text>

      <div className={styles.row}>
        <Button
          backgroundColor="#5B13AE"
          borderRadius="4px"
          height="28px"
          width="182px"
          fontColor="white"
          fontWeight="700"
        >
          #PTEJAKGJAGJAGA
        </Button>

        <Button
          backgroundColor="orange"
          borderRadius="4px"
          height="28px"
          width="70px"
          fontColor="white"
          fontWeight="700"
        >
          On Park
        </Button>
      </div>

      <IconButton
        backgroundColor="transparent"
        fontColor="white"
        fontWeight="700"
        border="1px solid #AE7CE7"
        icon={<ArrowForward />}
      >
        Got to Project
      </IconButton>
    </div>
  );
}

export default DesignLeadAction;
