import React from "react";
import styles from "./ArtistHomepage.module.scss";
import ArtistNavbar from "../../Molecules/navbar/ArtistNavbar";
import Common from "../../Molecules/header/Common";
import ArtistGreet from "../../Molecules/header/ArtistGreet";
import ArtistBanner from "../../Molecules/header/ArtistBanner";
import WipJobs from "../../Molecules/table/artist/WipJobs";
import ApprovalGraph from "../../Molecules/graphs/artist/ApprovalGraph";

function ArtistHomepage() {
  return (
    <div className={styles.container}>
      <div className={styles.navSection}>
        <ArtistNavbar />
      </div>

      <div className={styles.bodySection}>
        <div className={styles.section1}>
          <Common />
        </div>
        <div className={styles.section1}>
          <ArtistGreet />
        </div>
        <div className={styles.section1}>
          <ArtistBanner />
        </div>
        <div className={styles.section1}>
          <WipJobs />
        </div>
        <div className={styles.section1}>
          <WipJobs />
        </div>

        <div className={styles.rowSection}>
          <ApprovalGraph />
          <ApprovalGraph />
        </div>
        <div className={styles.footer}>
          <p>Copyrights 2024 @Advertflair Pvt Ltd</p>
        </div>
      </div>
    </div>
  );
}

export default ArtistHomepage;
