import React from "react";
import styles from "./styles.module.scss";
import {
  ArticleOutlined,
  CheckCircleOutline,
  DesignServicesOutlined,
  GridViewOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import NavButton from "../../Atoms/navbutton/NavButton";
function IqaNavbar() {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/navbar_logo.webp"
          alt=""
        />
      </div>
      <ul>
        <NavButton
          backgroundColor="white"
          fontColor="#5B4A74"
          fontFamily="Urbanist"
          icon={<GridViewOutlined />}
          fontSize="14px"
          gap="24px"
        >
          Dashboard
        </NavButton>

        <NavButton
          backgroundColor="white"
          fontColor="#5B4A74"
          fontFamily="Urbanist"
          icon={<ArticleOutlined />}
          fontSize="14px"
          gap="24px"
        >
          Reports
        </NavButton>

        <NavButton
          backgroundColor="white"
          fontColor="#5B4A74"
          fontFamily="Urbanist"
          icon={<CheckCircleOutline />}
          fontSize="14px"
          gap="24px"
        >
          Checklists
        </NavButton>

        <NavButton
          backgroundColor="white"
          fontColor="#5B4A74"
          fontFamily="Urbanist"
          icon={<DesignServicesOutlined />}
          fontSize="14px"
          gap="24px"
        >
          Checklists
        </NavButton>

        <NavButton
          backgroundColor="white"
          fontColor="#5B4A74"
          fontFamily="Urbanist"
          icon={<SettingsOutlined />}
          fontSize="14px"
          gap="24px"
        >
          Settings
        </NavButton>
      </ul>
    </div>
  );
}

export default IqaNavbar;
