import React, { useState } from 'react';
import styles from '../complianceCheck/ComplianceContainer.module.scss';

const JobAssignContainer = () => {
  const [selectedOption, setSelectedOption] = useState({
    value: 'Yahini',
    imgSrc: '/advertflair.png'
  });
  const [isOpen, setIsOpen] = useState(false);

  const options = [
    { value: 'Yahini', imgSrc: '/advertflair.png' },
    { value: 'Immanuel', imgSrc: '/advertflair.png' },
    { value: 'Boobalan', imgSrc: '/advertflair.png' },
    { value: 'Saranya', imgSrc: '/advertflair.png' }
  ];

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <img src="/assets/icons/backArrow.png" alt="backArrow" className={styles.image} />
        <h1 className={styles.heading}>Job Assigner</h1>
      </div>
      <div className={styles.mainSection}>
        <div className={styles.section1}>
          <div className={styles.subSection1}>
            <p>POD ID</p>
            <input type="text" />
          </div>
          <div className={styles.subSection2}>
            <p>Time Duration</p>
            <input type="text" />
          </div>
          <div className={styles.subSection3}>
            <p>Select Artist</p>
            <div className={styles.customSelectWrapper}>
              <div
                className={styles.customSelectTrigger}
                onClick={() => setIsOpen(!isOpen)}
              >
                <img src={selectedOption.imgSrc} alt={selectedOption.value} className={styles.roundedImage} />
                <span>{selectedOption.value}</span>
              </div>
              {isOpen && (
                <div className={styles.customOptions}>
                  {options.map(option => (
                    <div
                      key={option.value}
                      className={styles.customOption}
                      onClick={() => handleSelectOption(option)}
                    >
                      <img src={option.imgSrc} alt={option.value} className={styles.roundedImage} />
                      {option.value}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.section2}>
          <div className={styles.subSection1}>
            <p>Points</p>
            <input type="text" />
          </div>
          <div className={styles.subSection2}>
            <p>Level</p>
            <input type="text" />
          </div>
          <div className={styles.subSection3}>
            <p>Reference Images</p>
            <div className={styles.imageSection}>
              <img src="/assets/graphics/watch1.jpg" alt="watch" />
              <img src="/assets/graphics/watch1.jpg" alt="watch" />
              <img src="/assets/graphics/watch1.jpg" alt="watch" />
              <div className={styles.overlay}>
                <img src="/assets/graphics/watch1.jpg" alt="watch" />
                <div className={styles.overlayText}>+2</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.section4}>
          <hr />
          <button className={styles.secondaryButton}>Cancel</button>
          <button className={styles.startButton}>Assign Job</button>
        </div>
      </div>
    </div>
  );
};

export default JobAssignContainer;
