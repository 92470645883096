import React from 'react'
import styles from './ProductDetails.module.scss'

const ProductDetails = () => {
  return (
    <div className={styles.container}>
        <p className={styles.productType}>Product Type</p>
        <h1 className={styles.product}>Single Product</h1>
        <hr />
        <h1 className={styles.product}>Product Specifications</h1>
        <hr/>
        <p className={styles.productType}>Product Name 01</p>
        <h2 className={styles.productDescription}>Apple loremIpsum Dolar</h2>
         <div className={styles.section1}>
            <div className={styles.listItems}>
         <ul className={styles.items}>
            <ul>
            <li className={styles.productType}>Length</li>
            <li className={styles.productDescription}>0.22cm</li>
            </ul>
        <ul>
            <li className={styles.productType}>Width</li>
            <li className={styles.productDescription}>0.22cm</li>
        </ul>
        <ul>
        <li className={styles.productType}>Height</li>
        <li className={styles.productDescription}>0.22cm</li>
        </ul>

        <ul>
        <li className={styles.productType}>Focal Length</li>
        <li className={styles.productDescription}>0.22cm</li>
        </ul>
         </ul>
       
         </div>
         <div className={styles.images}>
         <img src='/assets/graphics/watch1.jpg' alt='watch'/>
         <img src='/assets/graphics/watch1.jpg' alt='watch'/>

         <div className={styles.overlayContainer}>
            <img src='/assets/graphics/watch1.jpg' alt='watch' />
            <div className={styles.overlay}>+04</div>
          </div>
         {/* <img src='/assets/graphics/watch1.jpg' alt='watch'/> */}
         
         </div>
      

        </div>
        <div className={styles.section3}>
        <div>
        <p className={styles.productType}>External Reference link</p>
         <p className={styles.productDescription}>www.referenceitems.com</p>
        </div>

         <p className={styles.productDescription}>Download Attachments</p>
         </div>
         <hr/>
         <p className={styles.productType}>Product Name 01</p>
        <h2 className={styles.productDescription}>Apple loremIpsum Dolar</h2>
         <div className={styles.section1}>
            <div className={styles.listItems}>
         <ul className={styles.items}>
            <ul>
            <li className={styles.productType}>Length</li>
            <li className={styles.productDescription}>0.22cm</li>
            </ul>
        <ul>
            <li className={styles.productType}>Width</li>
            <li className={styles.productDescription}>0.22cm</li>
        </ul>
        <ul>
        <li className={styles.productType}>Height</li>
        <li className={styles.productDescription}>0.22cm</li>
        </ul>

        <ul>
        <li className={styles.productType}>Focal Length</li>
        <li className={styles.productDescription}>0.22cm</li>
        </ul>
         </ul>
       
         </div>
         <div className={styles.images}>
         <img src='/assets/graphics/watch1.jpg' alt='watch'/>
         <img src='/assets/graphics/watch1.jpg' alt='watch'/>

         <div className={styles.overlayContainer}>
            <img src='/assets/graphics/watch1.jpg' alt='watch' />
            <div className={styles.overlay}>+04</div>
          </div>
         {/* <img src='/assets/graphics/watch1.jpg' alt='watch'/> */}
         
         </div>
      
         
        </div>
        <div className={styles.section3}>
        <div>
        <p className={styles.productType}>External Reference link</p>
         <p className={styles.productDescription}>www.referenceitems.com</p>
        </div>

         <p className={styles.productDescription}>Download Attachments</p>
         </div>


         <hr/>
         <p className={styles.productType}>Description</p>
         <p className={styles.productDescription}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere fugit illo ex fugiat commodi magni, molestiae maiores, nostrum ducimus laborum placeat, impedit quo? Numquam, porro quisquam nesciunt incidunt molestiae sunt deleniti totam illo ullam reiciendis excepturi ratione cumque a. Beatae repudiandae molestias at dolores ducimus, debitis excepturi laborum illum quo?</p>
    </div>
  )
}

export default ProductDetails