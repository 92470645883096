import React, { useEffect, useState } from "react";
import DesignLeadDashboard from "../../Templates/dashboard/DesignLeadDashboard";
import DesignLeadNavbar from "../../Molecules/navbar/DesignLeadNavbar";
import styles from "./styles.module.scss";
import { ClearAllOutlined, PriorityHighOutlined } from "@mui/icons-material";
function InvalidFeedbacks() {
  const [feedbacks, setFeedbacks] = useState([]);

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.api.advflr.com/studio/common/tables/invalid-feedbacks.php?feedback_status=Pending"
        );
        const data = await response.json();
        setFeedbacks(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function
  }, []);
  return (
    <div>
      <DesignLeadDashboard navbar={<DesignLeadNavbar />}>
        <div className={styles.container}>
          <div className={styles.titleSection}>Invalid Feedbacks</div>
          <div className={styles.tabSection}>
            <div className={styles.tabs}>
              <ClearAllOutlined />
              <p>All - 12</p>
            </div>

            <div className={styles.tabs}>
              <PriorityHighOutlined />
              <p>High Priority - 0</p>
            </div>
          </div>

          <div className={styles.searchSection}>
            <input type="text" placeholder="Enter your POID here" />
          </div>
          <div className={styles.tableSection}>
            <table>
              <thead>
                <tr>
                  <td>ID</td>
                  <td>POID</td>
                  <td>Feedback Image</td>
                  <td>Artist</td>
                  <td>QC Artist</td>
                  <td>Comment</td>
                  <td>Review</td>
                </tr>
              </thead>
              <tbody>
                {feedbacks.map((feedback, index) => (
                  <tr key={feedback.id}>
                    <td>{index + 1}</td>
                    <td>{feedback.partner_id}</td>
                    <td>
                      <img src={feedback.image_path} alt="" />
                    </td>
                    <td>{feedback.submited_by}</td>
                    <td>{feedback.qc_artist}</td>
                    <td>
                      <div className={styles.comment}>
                        {feedback.submission_comment}
                      </div>
                    </td>

                    <td>
                      <button>Review</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </DesignLeadDashboard>
    </div>
  );
}

export default InvalidFeedbacks;
