import React from "react";
import styles from "./HourlyUpdater.module.scss";

function HourlyUpdater() {
  return (
    <div className={styles.container}>
      <div className={styles.titleSection}>Hourly Updater Form</div>
      <div className={styles.contentSection}>
        <div className={styles.inputField}>
          <label htmlFor="">Employee ID</label>
          <input type="text" name="" placeholder="Employee ID Here" id="" />
        </div>

        <div className={styles.inputField}>
          <label htmlFor="">Date</label>
          <input
            type="date"
            name=""
            id=""
            placeholder="Please Choose date here"
          />
        </div>

        <div className={styles.inputField}>
          <label htmlFor="">Time</label>
          <select name="" id="">
            <option value="" disabled selected>
              Please Choose time
            </option>
            <option value="">08:00 AM to 09:00 AM</option>
            <option value="">09:00 AM to 10:00 AM</option>
            <option value="">10:00 AM to 11:00 AM</option>
            <option value="">11:00 AM to 12:00 PM</option>
          </select>
        </div>

        <div className={styles.inputField}>
          <label htmlFor="">Tasks done</label>
          <input type="text" name="" id="" placeholder="Enter tasks here" />
        </div>
      </div>
      <div className={styles.buttonSection}>
        <button className={styles.secondary}>Cancel</button>
        <button className={styles.primary}>Submit</button>
      </div>
    </div>
  );
}

export default HourlyUpdater;
