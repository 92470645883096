import React from 'react'
import styles from './ComplianceCheck.module.scss'
import ArtistNavbar from '../assignNewJob/ArtistNavbar'
import Common from '../header/Common'
// import CommonCheckbox from '../common/CommonCheckbox'
import ComplianceContainer from './ComplianceContainer'

const ComplianceCheck = () => {
  return (
    <div className={styles.container}>
    <div className={styles.navSection}>
   <ArtistNavbar />
 </div>

 <div className={styles.bodySection}>
   <div className={styles.section1}>
     <Common />
   </div>
   <div className={styles.section2}>
   <ComplianceContainer/>
</div>
<div className={styles.section3}>
  {/* <ArtistList/> */}
</div>
<div className={styles.footer}>
   <p>Copyrights 2024 @Advertflair Pvt Ltd</p>
</div>
</div>
</div>
  )
}

export default ComplianceCheck