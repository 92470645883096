import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Unauthorized.module.scss';

const Unauthorized = () => {
  return (
    <div className={styles.container}>
      <div className={styles.section1}>
        <img src="/assets/graphics/purple-lock.jpg" alt="Unauthorized" className={styles.lockImage} />
        <p className={styles.accessMessage}>Access Denied</p>
        <p className={styles.message}>You don't have permission to view this page.</p>
        <Link to="https://3dstudio.advertflair.com/" className={styles.startButton}>
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default Unauthorized;
