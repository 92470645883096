import React from "react";
import styles from "./Banner.module.scss";

function ArtistBanner() {
  return (
    <div className={styles.container}>
      <div className={styles.textSection}>
        <div className={styles.label}>Your Action Required</div>
        <div className={styles.title}>Job #s23425324 - Moved to Hold</div>
      </div>

      <button>Start Progress</button>
    </div>
  );
}

export default ArtistBanner;
