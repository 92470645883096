import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./RevisionComplianceContainer.module.scss";
import GlobalSearch from "../../Atoms/Inputs/globalsearch/GlobalSearch";
import Pagination from "./Pagination";

const RevisionComplianceContainer = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []); // Fetch data only once on component mount

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://www.api.advflr.com/studio/common/jobs.php?final_status=REVISION%20CCHECK"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCheckClick = (poid) => {
    navigate(`/revision-cc/${poid}`);
  };

  const renderTableRows = () => {
    if (!Array.isArray(data)) return null; // Handle case where data is not an array

    const startIndex = (currentPage - 1) * itemsPerPage;
    const selectedData = data.slice(startIndex, startIndex + itemsPerPage);
    return selectedData.map((item, index) => (
      <tr key={index}>
        <td>{item.username}</td>
        <td>{item.partner_id}</td>
        <td>{item.Lot}</td>
        <td>{item.team}</td>
        <td>{item.lastqc_process}</td>
        <td>
          <button onClick={() => handleCheckClick(item.partner_id)}>
            Check
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <img
          src="/assets/icons/backArrow.png"
          alt="backArrow"
          className={styles.image}
        />
        <h1 className={styles.heading}>Revision Compliance Check</h1>
      </div>
      <div className={styles.bodySection}>
        <div className={styles.leftSection}>
          <GlobalSearch />
        </div>
        <div className={styles.tableSection}>
          <table>
            <thead>
              <tr>
                <td>Username</td>
                <td>POID</td>
                <td>LOT</td>
                <td>Team</td>
                <td>Last QC Process</td>
                <td></td>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </table>
        </div>
        <hr />
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default RevisionComplianceContainer;
