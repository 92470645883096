import React, { useEffect, useState } from "react";
import styles from "./ArtistSelect.module.scss";

function ArtistSelect({ onArtistChange }) {
  const handleChange = (event) => {
    const artist = event.target.value;
    onArtistChange(artist);
  };

  const [artists, setArtists] = useState([]);

  useEffect(() => {
    const fetchArtistData = async () => {
      try {
        const response = await fetch(
          "https://www.api.advflr.com/studio/common/artist_details.php"
        );
        const data = await response.json();
        setArtists(data);
      } catch (error) {
        console.error("Error fetching artist data:", error);
      }
    };

    fetchArtistData();
  }, []);

  return (
    <div className={styles.container}>
      <select onChange={handleChange}>
        <option value="" selected disabled>
          Choose Artist Name
        </option>
        {artists.map((artist) => (
          <option
            key={artist.id}
            value={artist.username}
          >{`${artist.name} - ${artist.emp_id} ( ${artist.team} )`}</option>
        ))}
      </select>
    </div>
  );
}

export default ArtistSelect;
