import React from "react";
import DesignLeadDashboard from "../../Templates/dashboard/DesignLeadDashboard";
import DesignLeadNavbar from "../../Molecules/navbar/DesignLeadNavbar";
import LeaderBoard from "../../Molecules/table/leaderboards/LeaderBoard";
import CommonHeader from "../../Molecules/header/commonheader/CommonHeader";

function LeaderboardPage() {
  return (
    <DesignLeadDashboard navbar={<DesignLeadNavbar />}>
      <CommonHeader />
      <LeaderBoard />
    </DesignLeadDashboard>
  );
}

export default LeaderboardPage;
