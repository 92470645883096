import React from "react";
import styles from "./GlobalSearch.module.scss";
import { SearchOutlined } from "@mui/icons-material";
function GlobalSearch() {
  return (
    <div className={styles.container}>
      <SearchOutlined className={styles.icon} color="inherit" />
      <input type="text" placeholder="Search" />
    </div>
  );
}

export default GlobalSearch;
