import React from 'react'
import styles from './ArtistList.module.scss'

const ArtistList = () => {
  return (
    <div className={styles.container}>
    <h1 className={styles.artist}>All Artists</h1>
   

      <div className={styles.tableSection}>
        <table>
          <thead>
            <tr>
              <td>Artists Name</td>
              <td>Points</td>
              <td>Approved</td>
              <td>Email</td>
              <td>Location</td>
              
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Andrew Wilson</td>
              <td>345</td>
              <td>20 Jobs</td>
              <td>andrew@advertflair.com</td>
              <td>United States</td>
              
            </tr>

            <tr>
            <td>Andrew Wilson</td>
              <td>345</td>
              <td>20 Jobs</td>
              <td>andrew@advertflair.com</td>
              <td>United States</td>
              
            </tr>
            <tr>
            <td>Andrew Wilson</td>
              <td>345</td>
              <td>20 Jobs</td>
              <td>andrew@advertflair.com</td>
              <td>United States</td>
              
            </tr>
            <tr>
            <td>Andrew Wilson</td>
              <td>345</td>
              <td>20 Jobs</td>
              <td>andrew@advertflair.com</td>
              <td>United States</td>
              
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ArtistList