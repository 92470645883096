import React from "react";
import styles from "./Common.module.scss";

function Common() {
  return (
    <div className={styles.container}>
      <div className={styles.searchSection}>
        <img src="/assets/icons/searchicon.png" alt="" />
        <input type="text" placeholder="Search Here" />
      </div>
      <div className={styles.iconSection}>
        <img src="/assets/icons/chaticon.png" alt="" />
        <img src="/assets/icons/notificationicon.png" alt="" />
        <img src="/advertflair.png" className={styles.profile} alt="" />
      </div>
    </div>
  );
}

export default Common;
