import React, { useState, useEffect } from "react";
import styles from "./ItTaskboard.module.scss";
import ItTeam from "../../components/team-testimony/ItTeam";
import { CheckCircle, PauseCircle, PlayCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

function ItTaskboard() {
  const [formData, setFormData] = useState({
    taskName: "",
    skillsNeeded: "",
    complexity: "",
    priority: "",
    assigner: "",
    assignedTo: "",
  });

  const [tasks, setTasks] = useState([]);
  const [count, setCount] = useState("");
  const [processing, setProcessing] = useState(false);

  const fetchTasks = async () => {
    try {
      const response = await fetch(
        "https://www.api.advflr.com/studio/it-team/list-tasks.php"
      );
      const data = await response.json();
      setTasks(data);
      setCount(data.length);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const empId = Cookies.get("emp_id");

    if (!empId) {
      navigate("/");
    } else {
    }
  }, [navigate]);

  const developer = Cookies.get("emp_username");

  const startFunction = (id, task) => {
    setProcessing(true);
    axios
      .post(
        "https://www.api.advflr.com/studio/it-team/start-task.php",
        {
          id,
          developer,
          task,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        const data = await response.data;
        if (response.status === 201) {
          fetchTasks();
          toast.success(data.success);
        } else if (response.status === 500) {
          toast.error(data.error);
        }
        setProcessing(false);
      })
      .catch((error) => {
        console.error("Error starting task:", error);
        toast.error(error);
        setProcessing(false);
      });
  };

  const holdFunction = (id, task) => {
    setProcessing(true);
    axios
      .post(
        "https://www.api.advflr.com/studio/it-team/hold-task.php",
        {
          id,
          developer,
          task,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        const data = await response.data;
        if (response.status === 201) {
          toast.success(data.success);
          fetchTasks();
        } else if (response.status === 500) {
          toast.error(data.error);
        }
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        console.error("Error Holding task:", error);
        toast.error(error);
      });
  };

  const completeFunction = (id, task) => {
    setProcessing(true);
    axios
      .post(
        "https://www.api.advflr.com/studio/it-team/complete-task.php",
        {
          id,
          developer,
          task,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        const data = await response.data;
        if (response.status === 201) {
          fetchTasks();
          toast.success(data.success);
        } else if (response.status === 500) {
          toast.error(data.error);
        }
        setProcessing(false);
      })
      .catch((error) => {
        console.error("Error Completing task:", error);
        toast.error(error);
        setProcessing(false);
      });
  };

  const disabledFunction = () => {
    toast.error("Sorry this Function is not available");
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    if (
      !formData.taskName ||
      !formData.skillsNeeded ||
      !formData.complexity ||
      !formData.priority ||
      !formData.assigner ||
      !formData.assignedTo
    ) {
      toast.error("Please input all values");
      setProcessing(false);
      return;
    }

    try {
      const response = await fetch(
        "https://www.api.advflr.com/studio/it-team/new-task.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        fetchTasks();
        toast.success(data.success);
        setFormData({
          taskName: "",
          skillsNeeded: "",
          complexity: "",
          priority: "",
          assigner: "",
          assignedTo: "",
        });
      } else {
        toast.error(data.error);
      }
      setProcessing(false);
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      setProcessing(false);
    }
  };
  return (
    <div>
      <div className="py-2 px-4 flex justify-end bg-orange-300 text-md font-bold ">
        Welcome Back, {developer}
      </div>
      <div className={styles.container}>
        <div className={styles.rowSection}>
          <div className={styles.leftSection}>
            <div className={styles.titleSection}>
              <h1>New Task / Creation</h1>
              <p>Connecting ideas, creating impact.</p>

              <div className={styles.formSection}>
                <div className={styles.form}>
                  <label htmlFor="">Task Name</label>
                  <input
                    type="text"
                    placeholder="Enter Task Here"
                    id="taskName"
                    name="taskName"
                    value={formData.taskName}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.form}>
                  <label htmlFor="">Skills</label>
                  <select
                    value={formData.skillsNeeded}
                    onChange={handleInputChange}
                    id="skillsNeeded"
                    name="skillsNeeded"
                  >
                    <option value="" disabled>
                      Choose Skills
                    </option>
                    <option>React Components</option>
                    <option>React Functions</option>
                    <option>React Native</option>
                    <option>Backend</option>
                    <option>Employee Exit</option>
                    <option>Employee Onboard</option>
                    <option>Database Update</option>
                    <option>PHP Frontend Functions</option>
                    <option>Software / Hardware Related Issues</option>
                    <option>Threejs Functions</option>
                    <option>Others</option>
                  </select>
                </div>

                <div className={styles.form}>
                  <label htmlFor="">Complexity</label>
                  <select
                    value={formData.complexity}
                    onChange={handleInputChange}
                    id="complexity"
                    name="complexity"
                  >
                    <option value="" disabled>
                      Choose Complexity Here
                    </option>
                    <option value={1}>Easy</option>
                    <option value={2}>Medium</option>
                    <option value={3}>Hard</option>
                    <option value={4}>Complex</option>
                  </select>
                </div>

                <div className={styles.form}>
                  <label htmlFor="">Priority</label>
                  <select
                    value={formData.priority}
                    id="priority"
                    name="priority"
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Choose Priority Here
                    </option>
                    <option value={1}>Low</option>
                    <option value={2}>Medium</option>
                    <option value={3}>Urgent</option>
                    <option value={4}>Very Urgent</option>
                  </select>
                </div>

                <div className={styles.form}>
                  <label htmlFor="">Assigner</label>
                  <select
                    value={formData.assigner}
                    id="assigner"
                    name="assigner"
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Choose Assigner Here
                    </option>
                    <option value="immangeek">Immanuel</option>
                    <option value="saranya_r">Saranya</option>
                    <option value="boobalan">Boobalan</option>
                    <option value="yahini">Yahini</option>
                  </select>
                </div>

                <div className={styles.form}>
                  <label htmlFor="">Assigned to</label>
                  <select
                    value={formData.assignedTo}
                    onChange={handleInputChange}
                    id="assignedTo"
                    name="assignedTo"
                  >
                    <option value="" disabled>
                      Choose Assigned to
                    </option>
                    <option value="immangeek">Immanuel</option>
                    <option value="saranya_r">Saranya</option>
                    <option value="boobalan">Boobalan</option>
                    <option value="yahini">Yahini</option>
                  </select>
                </div>
              </div>
            </div>

            <div className={styles.buttonSection}>
              <button
                disabled={processing}
                onClick={handleSubmit}
                className={styles.primary}
              >
                {processing ? "Processing" : "Create Task"}
              </button>
            </div>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.titleSection}>
              <h1>Overall Pending Tasks - {count}</h1>
              <p>Keeping Track of What's Left to Do.</p>

              <div className={styles.formSection}>
                <div className={styles.form}>
                  <label htmlFor="">Developer Name</label>
                  <select id="developerName" name="developerName">
                    <option value="Overall" selected>
                      All Developers
                    </option>
                    <option>Immanuel</option>
                    <option>Saranya</option>
                    <option>Boobalan</option>
                    <option>Yahini</option>
                  </select>
                </div>
                <table>
                  <thead>
                    <tr>
                      <td>JOB ID</td>
                      <td>Task Name</td>
                      <td>Responsible</td>
                      <td>Assigned On</td>
                      <td>Status</td>
                      <td>Completion</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {tasks.map((task) => (
                      <tr key={task.taskID}>
                        <td>{task.taskID}</td>
                        <td>{task.taskName}</td>
                        <td>{task.assignedTo}</td>
                        <td>{task.assignedDate}</td>
                        <td>{task.status}</td>
                        <td>{task.completedDate ? "Completed" : "Pending"}</td>
                        <td className="flex !gap-2 !py-6 !border-[.5px] !border-gray-600  text-slate-800">
                          <PlayCircle
                            onClick={
                              task.status === "WIP"
                                ? disabledFunction
                                : () =>
                                    startFunction(task.taskID, task.taskName)
                            }
                            className="cursor-pointer hover:animate-spin"
                          />
                          <PauseCircle
                            onClick={
                              task.status === "HOLD"
                                ? disabledFunction
                                : () => holdFunction(task.taskID, task.taskName)
                            }
                            className="cursor-pointer hover:text-red-700"
                          />
                          <CheckCircle
                            onClick={
                              task.status === "HOLD"
                                ? disabledFunction
                                : () =>
                                    completeFunction(task.taskID, task.taskName)
                            }
                            className="cursor-pointer hover:text-green-700"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ItTeam />
      <Toaster />
    </div>
  );
}

export default ItTaskboard;
