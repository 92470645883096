import React, { useState } from "react";
import styles from "./modalforms.module.scss";
import { Cancel } from "@mui/icons-material";
import ComplianceDuration from "../../Atoms/Inputs/compliance_duration/ComplianceDuration";
import Cookies from "js-cookie";
function RevisionCCForm({ isOpen, onClose, poid, onSubmit }) {
  const [formData, setFormData] = useState({
    compliance_by: Cookies.get("emp_username"),
    artist: "",
    poid: Cookies.get("poid"),
    complianceDuration: "",
  });

  const handleComplianceDurationChange = (duration) => {
    setFormData({
      ...formData,
      complianceDuration: duration,
      poid: Cookies.get("poid"),
      artist: Cookies.get("artist"),
    });
  };

  const handleCustomCategoryChange = (event) => {
    setFormData({ ...formData, customCategory: event.target.value });
  };

  const AssignFunction = async () => {
    try {
      const response = await fetch(
        "https://www.api.advflr.com//studio/common/compliance/revision-ccheck.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      console.log(data.error);
      if (!data.error) {
        // Handle successful login
        //isOpen = false;
        isOpen = null;
        onSubmit();
        alert("successfull");

        //Storing COokie Values
      } else {
        // Handle login error
        alert(data.error);
      }
    } catch (error) {
      alert(error);
      console.error("Error:", error);
    }
  };

  if (!isOpen) return null;

  const referenceFunction = () => {
    console.log("reference button clicked");
    const url = `https://3dstudio.advertflair.com/reference_updated_new.php?poid=${poid}`;
    window.open(url, "_blank");
  };

  return (
    <div className={styles.container}>
      <div className={styles.modalContent}>
        <div className={styles.titleSection}>
          <h1>Revision CCheck Form</h1>
          <Cancel
            onClick={onClose}
            className={styles.icon}
            style={{ color: "red" }}
          />
        </div>

        <div className={styles.contentSection}>
          <div className={styles.content}>POID : {poid}</div>
          <ComplianceDuration
            onDurationChange={handleComplianceDurationChange}
          />
          <input
            type="text"
            className={styles.content}
            value={formData.customCategory}
            onChange={handleCustomCategoryChange}
            placeholder="Reason ( Optional )"
          />
        </div>

        <div className={styles.footerSection}>
          <button className={styles.secondary} onClick={referenceFunction}>
            Feedbacks
          </button>
          <button className={styles.primary} onClick={AssignFunction}>
            Assign
          </button>
        </div>
      </div>
    </div>
  );
}

export default RevisionCCForm;
