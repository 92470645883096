import React from "react";
import styles from "./styles.module.scss";
import IqaNavbar from "../../Molecules/navbar/IqaNavbar";
import CommonHeader from "../../Molecules/header/commonheader/CommonHeader";
import IqaToolbar from "../../Molecules/header/toolbar/IqaToolbar";
import CompletedProject from "../../Molecules/widgets/CompletedProject";
import ActionRequiredPark from "../../Molecules/widgets/ActionRequiredPark";
import IqaWipTable from "../../Molecules/table/IqaWipTable";
import IqaParkTable from "../../Molecules/table/IqaParkTable";

function IqaDashboard() {
  return (
    <div className={styles.container}>
      <div>
        <IqaNavbar />
      </div>

      <div className={styles.content}>
        <CommonHeader />
        <IqaToolbar />

        <div className={styles.firstHeader}>
          <CompletedProject />
          <ActionRequiredPark />
        </div>

        <IqaWipTable />
        <IqaParkTable />
      </div>
    </div>
  );
}

export default IqaDashboard;
