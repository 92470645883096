import React from "react";
import FileAssignerForm from "../../../Molecules/forms/assignerform/FileAssignerForm";
import CommonTemplate from "../../../Templates/dashboard/CommonTemplate";

const FileAssignerPage = () => {
  return (
    <>
      <CommonTemplate>
        <FileAssignerForm />
      </CommonTemplate>
    </>
  );
};

export default FileAssignerPage;
