import React, { useState } from "react";
import styles from "./modalforms.module.scss";
import { Cancel } from "@mui/icons-material";
import Cookies from "js-cookie";
import ArtistSelect from "../../Atoms/Inputs/artist_selection/ArtistSelect";
function FileAssignModalForm({ isOpen, onClose, poid, onSubmit }) {
  const [formData, setFormData] = useState({
    assigner: Cookies.get("emp_username"),
    poid: Cookies.get("poid"),
    assigned_to: "",
    comment: "",
  });

  const handleArtistChange = (artist) => {
    setFormData({
      ...formData,
      assigned_to: artist,
      poid: Cookies.get("poid"),
    });
  };

  const handleCommentChange = (event) => {
    setFormData({ ...formData, comment: event.target.value });
  };

  const AssignFunction = async () => {
    try {
      const response = await fetch(
        "https://www.api.advflr.com/studio/common/assignment/assign.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      console.log(data.error);
      if (!data.error) {
        // Handle successful login
        //isOpen = false;
        isOpen = null;
        onSubmit();
        alert("successfull");

        //Storing COokie Values
      } else {
        // Handle login error
        alert(data.error);
      }
    } catch (error) {
      alert(error);
      console.error("Error:", error);
    }
  };

  if (!isOpen) return null;

  const referenceFunction = () => {
    console.log("reference button clicked");
    const url = `https://3dstudio.advertflair.com/reference_updated_new.php?poid=${poid}`;
    window.open(url, "_blank");
  };

  const clarificationFunction = () => {
    console.log("THis is clarification function");
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContent}>
        <div className={styles.titleSection}>
          <h1>File Assigner Form</h1>
          <Cancel
            onClick={onClose}
            className={styles.icon}
            style={{ color: "red" }}
          />
        </div>

        <div className={styles.contentSection}>
          <div className={styles.content}>POID : {poid}</div>

          <ArtistSelect onArtistChange={handleArtistChange} />
          <input
            type="text"
            className={styles.content}
            value={formData.customCategory}
            onChange={handleCommentChange}
            placeholder="Comment ( Optional )"
          />
        </div>

        <div className={styles.footerSection}>
          <button className={styles.secondary} onClick={clarificationFunction}>
            Clarification
          </button>
          <button className={styles.secondary} onClick={referenceFunction}>
            Reference
          </button>
          <button className={styles.primary} onClick={AssignFunction}>
            Assign
          </button>
        </div>
      </div>
    </div>
  );
}

export default FileAssignModalForm;
