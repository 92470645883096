import React from "react";
import styles from "./Text.module.scss";

function Text({ children, fontFamily, fontWeight, fontSize, color }) {
  const inlineStyle = {
    fontFamily,
    fontWeight,
    fontSize,
    color,
  };
  return (
    <span className={styles.text} style={inlineStyle}>
      {children}
    </span>
  );
}

export default Text;
