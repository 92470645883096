import React, { useState } from "react";
import styles from "./FileUploader.module.scss";
import ProgressBar from "@ramonak/react-progress-bar";

function FileUploader() {
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");

  const [deliverycontainer, setDeliverycontainer] = useState(true);
  const [sourcecontainer, setSourcecontainer] = useState(false);
  const [supportcontainer, setSupportcontainer] = useState(false);

  const uploadDelivery = async (event) => {
    try {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      setProgress(0);
      setMessage("");

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "https://3dstudio.advertflair.com/common/api/delivery.php"
      );

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          setProgress(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);

          if (response.success) {
            setDeliverycontainer(false);
            setSourcecontainer(true);
            setProgress(0);
            setMessage("");
            console.log("successfully uploaded");
          } else {
            console.log("Not uploaded");
            setMessage(response.error);
          }
        } else {
          setMessage("Error uploading file");
          console.error("Error uploading file");
        }
      };

      xhr.onerror = () => {
        setMessage("Error uploading file");
        console.error("Error uploading file");
      };

      xhr.send(formData);
    } catch (error) {
      setMessage("Error uploading file");
      console.error("Error uploading file:", error);
    }
  };

  const uploadSource = async (event) => {
    try {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      setProgress(0);
      setMessage("");

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "https://3dstudio.advertflair.com/common/api/source.php"
      );

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          setProgress(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);

          if (response.success) {
            setDeliverycontainer(false);
            setSourcecontainer(false);
            setSupportcontainer(true);
            setProgress(0);
            setMessage("");
            console.log("successfully uploaded");
          } else {
            console.log("Not uploaded");
            setMessage(response.error);
          }
        } else {
          setMessage("Error uploading file");
          console.error("Error uploading file");
        }
      };

      xhr.onerror = () => {
        setMessage("Error uploading file");
        console.error("Error uploading file");
      };

      xhr.send(formData);
    } catch (error) {
      setMessage("Error uploading file");
      console.error("Error uploading file:", error);
    }
  };

  const uploadSupport = async (event) => {
    try {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      setProgress(0);
      setMessage("");

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "https://3dstudio.advertflair.com/common/api/support.php"
      );

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          setProgress(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);

          if (response.success) {
            setDeliverycontainer(false);
            setSourcecontainer(false);
            setSupportcontainer(false);
            setProgress(0);
            setMessage("");
            console.log("successfully uploaded");

            var popup = document.getElementById("popup");
            popup.style.display = "block";

            // Redirect to "3dstudio.advertflair.com" after 3 seconds
            setTimeout(function () {
              window.location.href = "https://3dstudio.advertflair.com";
            }, 3000);
          } else {
            console.log("Not uploaded");
            setMessage(response.error);
          }
        } else {
          setMessage("Error uploading file");
          console.error("Error uploading file");
        }
      };

      xhr.onerror = () => {
        setMessage("Error uploading file");
        console.error("Error uploading file");
      };

      xhr.send(formData);
    } catch (error) {
      setMessage("Error uploading file");
      console.error("Error uploading file:", error);
    }
  };

  return (
    <>
      <div id="popup" className={styles.popup}>
        <p>
          Your file has been uploaded successfully. It will direct you to the
          homepage in 3 seconds. Thank you for your patience.
        </p>
      </div>
      {deliverycontainer && (
        <div className={styles.container}>
          <div className={styles.miniContainer}>
            <div className={styles.title}>
              Step 1 / 3: Delivery Uploader
              <a
                href="https://advertflair-public.s3.us-west-2.amazonaws.com/studio/artist-uploads/instructions/Delivery-Uploader.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Delivery - SOP
              </a>
            </div>

            <input
              type="file"
              accept=".zip"
              onChange={uploadDelivery}
              name="attachment"
            />

            {progress > 0 && (
              <ProgressBar
                borderRadius="4"
                bgColor="#777487"
                completed={progress}
                labelAlignment="center"
                height="32px"
              />
            )}

            {message && <div className={styles.message}>{message}</div>}
          </div>
        </div>
      )}

      {sourcecontainer && (
        <div className={styles.container}>
          <div className={styles.miniContainer}>
            <div className={styles.title}>
              Step 2 / 3: Source Uploader
              <a
                href="https://advertflair-public.s3.us-west-2.amazonaws.com/studio/artist-uploads/instructions/Source-Uploader.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Source - SOP
              </a>
            </div>

            <input
              type="file"
              accept=".zip"
              onChange={uploadSource}
              name="attachment"
            />

            {progress > 0 && (
              <ProgressBar
                borderRadius="4"
                bgColor="#777487"
                completed={progress}
                labelAlignment="center"
                height="32px"
              />
            )}

            {message && <div className={styles.message}>{message}</div>}
          </div>
        </div>
      )}

      {supportcontainer && (
        <div className={styles.container}>
          <div className={styles.miniContainer}>
            <div className={styles.title}>
              Step 3 / 3: Support Uploader
              <a
                href="https://advertflair-public.s3.us-west-2.amazonaws.com/studio/artist-uploads/instructions/Support-Uploader.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Support - SOP
              </a>
            </div>

            <input
              type="file"
              accept=".zip"
              onChange={uploadSupport}
              name="attachment"
            />

            {progress > 0 && (
              <ProgressBar
                borderRadius="4"
                bgColor="#777487"
                completed={progress}
                labelAlignment="center"
                height="32px"
              />
            )}

            {message && <div className={styles.message}>{message}</div>}
          </div>
        </div>
      )}
    </>
  );
}

export default FileUploader;
