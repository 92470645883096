import React from "react";
import styles from "./Support.module.scss";
import ArtistNavbar from "../navbar/ArtistNavbar";

import Common from "../header/Common";
import QuerySupport from "./QuerySupport";

const Support = () => {
  return (
    <div className={styles.container}>
      <div className={styles.navSection}>
        <ArtistNavbar />
      </div>

      <div className={styles.bodySection}>
        <div className={styles.section1}>
          <Common />
        </div>
        <div className={styles.section2}>
          <QuerySupport />
        </div>

        <div className={styles.footer}>
          <p>Copyrights 2024 @Advertflair Pvt Ltd</p>
        </div>
      </div>
    </div>
  );
};

export default Support;
