import React from "react";
import CommonTemplate from "../../../Templates/dashboard/CommonTemplate";
import ComplianceCheckTable from "../../../Molecules/table/ComplianceCheckTable";

const CCheckPage = () => {
  return (
    <>
      <CommonTemplate>
        <ComplianceCheckTable />
      </CommonTemplate>
    </>
  );
};

export default CCheckPage;
