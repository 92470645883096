import React from "react";
import styles from "./styles.module.scss";
import CommonHeader from "../../Molecules/header/commonheader/CommonHeader";
import CommonNavbar from "../../Molecules/navbar/CommonNavbar";

function CommonTemplate({ children }) {
  return (
    <div className={styles.container}>
      <div>
        <CommonNavbar />
      </div>
      <div className={styles.content}>
        <CommonHeader />
        {children}
      </div>
    </div>
  );
}

export default CommonTemplate;
