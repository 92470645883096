import React, { useState } from "react";
import styles from "./ArtistNavbar.module.scss";
import Navmenu from "../../Atoms/button/Navmenu";

function ArtistNavbar() {
  const [isShrunk, setIsShrunk] = useState(false);

  const handleShrinkToggle = () => {
    setIsShrunk(!isShrunk);
  };

  return (
    <div className={styles.navbarContainer}>
      <div className={`${styles.container} ${isShrunk ? styles.hidden : ""}`}>
        <div className={styles.logoSection}>
          <img src="/assets/icons/logo.png" alt="Logo" />
        </div>

        <div className={styles.menuSection}>
          <Navmenu icon={"/assets/icons/jobboardicon.png"} label="Job Board" />
          <Navmenu icon={"/assets/icons/leaderboardicon.png"} label="Leader Board" />
          <Navmenu icon={"/assets/icons/settingsicon.png"} label="Settings" />
          <Navmenu icon={"/assets/icons/supporticon.png"} label="Support" />
        </div>

        <div className={styles.footerSection}>
          <div className={styles.line}></div>
        </div>

        <div className={styles.shrinkSection} onClick={handleShrinkToggle}>
          <img src="/assets/icons/shrinkicon.png" alt="Shrink Icon" />
        </div>
      </div>

      <div className={`${styles.shrinked} ${!isShrunk ? styles.hidden : ""}`}>
        <div className={styles.logoSection}>
          <img src="/advertflair.png" alt="Logo" />
        </div>

        <div className={styles.menuSection}>
          <Navmenu icon={"/assets/icons/jobboardicon.png"} />
          <Navmenu icon={"/assets/icons/leaderboardicon.png"} />
          <Navmenu icon={"/assets/icons/settingsicon.png"} />
          <Navmenu icon={"/assets/icons/supporticon.png"} />
        </div>

        <div className={styles.footerSection}>
          <div className={styles.line}></div>
        </div>

        <div className={styles.shrinkSection} onClick={handleShrinkToggle}>
          <img src="/assets/icons/enlargeicon.png" alt="Enlarge Icon" />
        </div>
      </div>
    </div>
  );
}

export default ArtistNavbar;
