import React from 'react'
import styles from './DragDrop.module.scss'

const DragDrop = () => {
  return (
    <>
    <div className={styles.container}>
      <div className={styles.section1}>
      

  {/* <a href="#" className={styles.paginationLink}>1</a>
  <a href="#" className={styles.paginationLink }>2</a>
  <a href="#" className={styles.paginationLink}>3</a> */}
<div className=''>
  <img src="./assets/icons/delivery.png" alt="delivery" className={styles.image} />
  </div>
  <div>
    <img src="./assets/icons/work.png" alt="work" className={styles.image} />
  </div>
<div>
     <img src="./assets/icons/support.png" alt="support" className={styles.image}  />
</div>


      </div>
      <div className={styles.section2}>
        <h1 className={styles.title}>Drag & Drop File</h1>
        <p className={styles.description}> OR </p>
        <button className={styles.button}>Browse</button>
      </div>
    </div>
    <div className={styles.footer}>
        <div className={styles.button1}>
        <button className={styles.button}>Prev</button>
        </div>
        <div className={styles.button2}>
        <button className={styles.button}>Save for Later</button>
        <button className={styles.button}>Move to Hold</button>
        <button className={styles.startButton}>Complete Project</button>
        </div>
    </div>
    </>
  )
}

export default DragDrop