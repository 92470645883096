import React from "react";
import styles from "./artisttable.module.scss";

function WipJobs() {
  return (
    <div className={styles.container}>
      <div className={styles.titleSection}>
        <div className={styles.textSection}>
          <div className={styles.title}>Assigned Jobs</div>
          <div className={styles.description}>Lorem ipsum dolor sit.</div>
        </div>

        <div className={styles.iconSection}>
          <img src="/assets/icons/filtericon.png" alt="Icon" />
        </div>
      </div>

      <div className={styles.tableSection}>
        <table>
          <thead>
            <tr>
              <td>Job ID</td>
              <td>Complexity</td>
              <td>Lot</td>
              <td>Deadline</td>
              <td>Workflow</td>
              <td>Points</td>
              <td>Priority</td>
              <td>Status</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PIJADKGJA</td>
              <td>Easy</td>
              <td>WK-23-2024</td>
              <td>May 23,2024</td>
              <td>Recharter / Secondary</td>
              <td>24</td>
              <td>High</td>
              <td>Yet to Start</td>
              <td>
                <button>
                  <p>View</p>
                  <img src="/assets/icons/rightarrowicon.png" alt="" />
                </button>
              </td>
            </tr>

            <tr>
              <td className={styles.strong}>PIJADKG23</td>
              <td>Easy</td>
              <td>WK-23-2024</td>
              <td>May 23,2024</td>
              <td>Recharter / Secondary</td>
              <td>24</td>
              <td>High</td>
              <td>Yet to Start</td>
              <td>
                <button>
                  <p>View</p>
                  <img src="/assets/icons/rightarrowicon.png" alt="" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WipJobs;
