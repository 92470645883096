import React from "react";
import CommonTemplate from "../../../../Templates/dashboard/CommonTemplate";
import YtaTable from "../../../../Molecules/table/YtaTable";

function YtaJobsList() {
  return (
    <>
      <CommonTemplate>
        <YtaTable />
      </CommonTemplate>
    </>
  );
}

export default YtaJobsList;
