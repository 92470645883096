import React from "react";
import styles from "./CommonHeader.module.scss";
import GlobalSearch from "../../../Atoms/Inputs/globalsearch/GlobalSearch";
import {
  NotificationsNoneOutlined,
  PersonOutline,
  SmsOutlined,
} from "@mui/icons-material";
function CommonHeader() {
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <GlobalSearch />
      </div>

      <div className={styles.rightSection}>
        <SmsOutlined className={styles.icon} />
        <NotificationsNoneOutlined className={styles.icon} />
        <PersonOutline className={styles.icon} />
      </div>
    </div>
  );
}

export default CommonHeader;
