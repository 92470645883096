import React from "react";
import styles from "./ComplianceDuration.module.scss";

function ComplianceDuration({ onDurationChange }) {
  const handleChange = (event) => {
    const duration = event.target.value;
    onDurationChange(duration);
  };
  return (
    <div className={styles.container}>
      <select name="" id="" onChange={handleChange}>
        <option value="" selected>
          Choose File Duration
        </option>
        <option>00:30:00</option>
        <option>01:00:00</option>
        <option>01:30:00</option>
        <option>02:00:00</option>
        <option>02:30:00</option>
        <option>03:00:00</option>
        <option>03:30:00</option>
        <option>04:00:00</option>
        <option>04:30:00</option>
        <option>05:00:00</option>
        <option>05:30:00</option>
        <option>06:00:00</option>
        <option>06:30:00</option>
        <option>07:00:00</option>
        <option>07:30:00</option>
        <option>08:00:00</option>
        <option>08:30:00</option>
        <option>09:00:00</option>
        <option>09:30:00</option>
        <option>10:00:00</option>
        <option>10:30:00</option>
        <option>11:00:00</option>
        <option>11:30:00</option>
        <option>12:00:00</option>
      </select>
    </div>
  );
}

export default ComplianceDuration;
