import React from 'react';
import Common from '../header/Common';
import ArtistNavbar from '../assignNewJob/ArtistNavbar';
import styles from './RevisionComplianceCheck.module.scss';
import RevisionComplianceContainer from './RevisionComplianceContainer';

const RevisionComplianceCheckPage = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.navSection}>
          <ArtistNavbar />
        </div>

        <div className={styles.bodySection}>
          <div className={styles.section1}>
            <Common />
          </div>
          <div className={styles.section2}>
            <RevisionComplianceContainer />
          </div>

          <div className={styles.footer}>
            <p>Copyrights 2024 @Advertflair Pvt Ltd</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RevisionComplianceCheckPage;
