import React from 'react';
import styles from './AllArtists.module.scss';
import TopGainers from './TopGainers';

const AllArtists = () => {
  return (
    <div className={styles.container}>
      <div className={styles.section1}>
        <div className={styles.section2}>
          <h1 className={styles.name}>Leaderboard</h1>
          <p className={styles.description}>Lorem ipsum dolor</p>
        </div>
        <div className={styles.section3}>
          <div className={styles.item}>
            <div className={styles.customSelect}>
              <input type="checkbox" id="today" name="Today" />
              <label htmlFor="today">Today</label>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.customSelect}>
              <input type="checkbox" id="week" name="week" />
              <select>
                <option>Week 41</option>
                <option>Week 42</option>
                <option>Week 43</option>
                <option>Week 44</option>
                <option>Week 45</option>
              </select>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.customSelect}>
              <input type="checkbox" id="month" name="month" />
              <select>
                <option>January</option>
                <option>February</option>
                <option>March</option>
                <option>April</option>
                <option>May</option>
              </select>
            </div>
          
          </div>
          <div className={styles.customSelect}>
              <img src="/assets/icons/jobboardicon.png" alt="icon" className={styles.icon}/>
            </div>
        </div>
      </div>
      <div>

      </div>
      <div>
        <TopGainers/>
      </div>
    </div>
  );
}

export default AllArtists;
