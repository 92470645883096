import React from "react";
import styles from "./IconButton.module.scss";

function IconButton({
  children,
  icon,
  backgroundColor,
  backgroundImage,
  borderRadius,
  width,
  height,
  gap,
  fontColor,
  fontWeight,
  fontSize,
  fontFamily,
  border,
  onClick,
}) {
  const inlineStyle = {
    backgroundColor,
    backgroundImage,
    borderRadius,
    width,
    height,
    gap,
    color: fontColor,
    fontSize,
    fontWeight,
    fontFamily,
    border,
  };
  return (
    <button className={styles.iconbutton} style={inlineStyle} onClick={onClick}>
      {children}
      {icon && <span>{icon}</span>}
    </button>
  );
}

export default IconButton;
