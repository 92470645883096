import React from "react";
import DesignLeadDashboard from "../../Templates/dashboard/DesignLeadDashboard";
import DesignLeadNavbar from "../../Molecules/navbar/DesignLeadNavbar";
import CommonHeader from "../../Molecules/header/commonheader/CommonHeader";
import HourlyUpdater from "../../Molecules/widgets/hourly-updater/HourlyUpdater";

function HourlyUpdatePage() {
  return (
    <DesignLeadDashboard navbar={<DesignLeadNavbar />}>
      <CommonHeader />
      <HourlyUpdater />
    </DesignLeadDashboard>
  );
}

export default HourlyUpdatePage;
