import React, { useState } from "react";
import styles from "./styles.module.scss";
import IqaNavbar from "../../Molecules/navbar/IqaNavbar";
import CommonHeader from "../../Molecules/header/commonheader/CommonHeader";
import DesignLeadDashboard from "../../Templates/dashboard/DesignLeadDashboard";
import toast, { Toaster } from "react-hot-toast";

function PostInvalidFeedbacks() {
  const initialState = {
    partnerID: "",
    qcType: "",
    qcRounds: "",
    comment: "",
    attachment: null,
  };

  const [formData, setFormData] = useState(initialState);
  const [submitting, setSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      attachment: e.target.files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("partnerID", formData.partnerID);
    data.append("qcType", formData.qcType);
    data.append("qcRounds", formData.qcRounds);
    data.append("comment", formData.comment);
    if (formData.attachment) {
      data.append("attachment", formData.attachment);
    }
    setSubmitting(true);

    fetch(
      "https://www.api.advflr.com/studio/designer/submit-invalid-feedbacks.php",
      {
        method: "POST",
        body: data,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          toast.error(data.error);
        } else {
          setFormData({
            partnerID: "",
            qcType: "",
            qcRounds: "",
            comment: "",
            attachment: null,
          });
          toast.success(data.message);
        }
        setSubmitting(false);
      })
      .catch((error) => toast.error(error.message));
  };
  return (
    <DesignLeadDashboard navbar={<IqaNavbar />}>
      <CommonHeader />
      <div className={styles.container}>
        <div className={styles.inputSection}>
          <p>Partner ID</p>
          <input
            type="text"
            name="partnerID"
            placeholder="Enter your POID"
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputSectionHalf}>
          <div className={styles.column}>
            <p>QC Type</p>
            <select name="qcType" onChange={handleInputChange}>
              <option value="" selected disabled>
                Select rejection type
              </option>
              <option value="IQA">IQA</option>
              <option value="SQT">SQT</option>
            </select>
          </div>

          <div className={styles.column}>
            <p>QC Rounds</p>
            <select name="qcRounds" onChange={handleInputChange} id="">
              <option value="" selected disabled>
                Choose the rounds
              </option>
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
            </select>
          </div>
        </div>

        <div className={styles.inputSection}>
          <p>Attachment-1</p>
          <input type="file" name="attachment" onChange={handleFileChange} />
        </div>

        <div className={styles.inputSection}>
          <p>Comment-1</p>
          <input
            type="text"
            name="comment"
            placeholder="Enter the reason why it is invalid"
            onChange={handleInputChange}
          />
        </div>

        <div className={styles.row}>
          <button disabled={submitting}>Close</button>
          <button
            onClick={handleSubmit}
            className={styles.primary}
            disabled={submitting}
          >
            {submitting ? "Processing" : "Submit"}
          </button>
        </div>
      </div>
      <Toaster />
    </DesignLeadDashboard>
  );
}

export default PostInvalidFeedbacks;
