import React from 'react'
import styles from './QuerySupportContainer.module.scss'

const QuerySupportContainer = () => {
  return (
    <div className={styles.container}>
         
        
         <div className={styles.bodySection}>
         <div className={styles.section1}>
          <div>
         <img src='/assets/icons/InfoIcon.png' alt='profile' className={styles.logo}/>
         <h3 className={styles.artistName}>Report an Issue</h3>
         <p className={styles.description}>Create a ticket and raise issue</p>
         </div>
         <div>
    
         <img src='/assets/icons/InfoRightArrow.png' alt='profile' className={styles.profile}/>

         </div>

         </div>
         <div className={styles.section2}>
         <div>
         <img src='/assets/icons/liveSupport.png' alt='liveSupport' className={styles.logo}/>
         <h3 className={styles.artistName}>Live Support</h3>
         <p className={styles.description}>Lorem ipsum dolor sit amet</p>
         </div>
         <div>
       
         <img src='/assets/icons/InfoRightArrow.png' alt='rightArrow' className={styles.profile}/>
         </div>
         </div>
         <div className={styles.section3}>
         <div>
         <img src='/assets/icons/question.png' alt='question' className={styles.logo}/>
         <h3 className={styles.artistName}>FAQ's</h3>
         <p className={styles.description}>Lorem ipsum dolor sit amet</p>
         </div>
         <div>
      
         <img src='/assets/icons/InfoRightArrow.png' alt='profile' className={styles.profile}/>
         </div>
         </div>

         </div>

         <div className={styles.childContainer}>

            <img src="/assets/graphics/slack_logo.png" alt="slack_logo" className={styles.slackLogo} />
            <div>
                <p className={styles.content1}>Collaborate more with team</p>
                <p className={styles.content2}>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
            </div>
            <button className={styles.startButton}>Start Discussion</button>
         </div>

    </div>

  )
}

export default QuerySupportContainer