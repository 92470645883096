import React from "react";
import styles from "./Button.module.scss";

function Button({
  children,
  backgroundColor,
  backgroundImage,
  borderRadius,
  width,
  height,
  fontColor,
  fontWeight,
  fontFamily,
  onClick,
}) {
  const inlineStyle = {
    backgroundColor,
    backgroundImage,
    width,
    height,
    borderRadius,
    color: fontColor,
    fontWeight,
    fontFamily,
  };
  return (
    <span className={styles.button} style={inlineStyle} onClick={onClick}>
      {children}
    </span>
  );
}

export default Button;
