import React from 'react';
import styles from './Pagination.module.scss';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      if (i <= 4 || i === totalPages) {
        pageNumbers.push(
          <span
            key={i}
            className={`${styles.pageNumber} ${currentPage === i ? styles.active : ''}`}
            onClick={() => onPageChange(i)}
          >
            {i}
          </span>
        );
      } else if (i === 5) {
        pageNumbers.push(<span key="dots" className={styles.dots}>...</span>);
      }
    }
    return pageNumbers;
  };

  return (
    <div className={styles.pagination}>
      <span
        className={styles.pageNumber}
        onClick={() => onPageChange(Math.max(1, currentPage - 1))}
      >
        {'<'}
      </span>
      {renderPageNumbers()}
      <span
        className={styles.pageNumber}
        onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
      >
        {'>'}
      </span>
    </div>
  );
};

export default Pagination;
