import React from "react";

import Common from "../header/Common";

import ArtistNavbar from "../assignNewJob/ArtistNavbar";
import styles from "./RevisionComplianceCheck.module.scss";
import CCheckForm from "../forms/compliancecheck/CCheckForm";

const RevisionComplianceCheck = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.navSection}>
          <ArtistNavbar />
        </div>

        <div className={styles.bodySection}>
          <div className={styles.section1}>
            <Common />
          </div>
          <div className={styles.section2}>
            <CCheckForm />
          </div>

          <div className={styles.footer}>
            <p>Copyrights 2024 @Advertflair Pvt Ltd</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RevisionComplianceCheck;
