import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Text from "../../Atoms/text/Text";
import FileAssignModalForm from "../modal-forms/FileAssignModalForm";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";

function ReassignTable() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [dataloaded, setDataloaded] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [artists, setArtists] = useState([]);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const fetchArtistData = async () => {
    try {
      const response = await fetch(
        "https://www.api.advflr.com/studio/common/artist_details.php"
      );
      const data = await response.json();
      setArtists(data);
    } catch (error) {
      console.error("Error fetching artist data:", error);
    }
  };

  async function assignFunction(data) {
    console.log("click owrking");
    setIsSubmitting(true);
    const assigner = Cookies.get("emp_username");
    const { artist, poid, reason } = data;

    try {
      const response = await fetch(
        "https://www.api.advflr.com/studio/common/assignment/reassign.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ artist, poid, reason, assigner }),
        }
      );

      const data = await response.json();
      console.log(data.error);
      if (!data.error) {
        toast.success(data.success);
        reset();
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      //alert(error);
      toast.error(error);
      //console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://www.api.advflr.com/studio/common/jobs.php?final_status=HOLD"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData); // Assuming API response is an array of objects as shown in your example
      setCount(jsonData.length);
      setDataloaded(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchArtistData();
  }, []);

  return (
    <div className={styles.container}>
      <Text
        fontFamily="Urbanist"
        fontSize="22px"
        color="#311057"
        fontWeight="700"
      >
        {dataloaded ? (
          <>Hold Jobs - {count}</>
        ) : (
          <>There is no jobs at the moment</>
        )}
      </Text>

      <div className={styles.line}></div>

      <form
        className={styles.formSection}
        onSubmit={handleSubmit(assignFunction)}
      >
        <h1>File Reassigner - Form</h1>
        <div className={styles.inputSection}>
          <input
            placeholder="Enter Your POID"
            {...register("poid", { required: "POID is required" })}
          />

          <select
            {...register("artist", { required: "Please Choose artist name" })}
          >
            <option disabled>Choose Artist Name</option>
            {artists.map((artist) => (
              <option
                key={artist.emp_id}
                value={artist.username}
              >{`${artist.name} - ${artist.emp_id} ( ${artist.team} )`}</option>
            ))}
          </select>

          <select
            placeholder="Enter the reason"
            {...register("reason", {
              required: "Reason is required",
            })}
          >
            <option disabled>Choose reason</option>
            <option>Artist Inefficiency</option>
            <option>Artist Absent</option>
            <option>Artist Idle</option>
          </select>

          <button
            type="submit"
            className={styles.primary}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Please Wait " : "Reassign"}
          </button>
        </div>
      </form>

      {dataloaded && (
        <table>
          <thead>
            <tr>
              <td>POID</td>
              <td>Designer</td>
              <td>Workflow</td>
              <td>Process</td>
              <td>Lot</td>
              <td>Team</td>
              <td>Last QC</td>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.partner_id}</td>
                <td>{item.username}</td>
                <td>{item.workflow}</td>
                <td>{item.process}</td>
                <td>{item.Lot}</td>
                <td>{item.team}</td>
                <td>{item.lastqc_process}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Toaster />
      <FileAssignModalForm isOpen={modalOpen} onClose={handleModalClose} />
    </div>
  );
}

export default ReassignTable;
