import React from 'react'
import ArtistNavbar from '../assignNewJob/ArtistNavbar'
import CommonCheckbox from '../common/CommonCheckbox'
import Common from '../header/Common'
import styles from './FileAssign.module.scss'
import CheckStatus from './CheckStatus'

const FileAssign = () => {
  return (
    <div className={styles.container}>
    <div className={styles.navSection}>
   <ArtistNavbar />
 </div>

 <div className={styles.bodySection}>
   <div className={styles.section1}>
     <Common />
   </div>
   <div className={styles.section2}>
   <CommonCheckbox/>
</div>
<div className={styles.section3}>
   <CheckStatus/>
</div>
<div className={styles.footer}>
   <p>Copyrights 2024 @Advertflair Pvt Ltd</p>
</div>
</div>
</div>
  )
}

export default FileAssign