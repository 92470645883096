import React from "react";
import styles from "./FileAssignerForm.module.scss";

function FileAssignerForm() {
  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.label}>Partner ID : Testing_2</div>
        <div className={styles.label}>Started : 2024:12:23 12:12:13</div>

        <select className={styles.label} name="" id="">
          <option value="" selected>
            {" "}
            Artist Name
          </option>
          <option value="">Immanuel A</option>
        </select>
      </div>

      <div className={styles.form}>
        <div className={styles.label}>QC Artist</div>
        <div className={styles.label}>Artist Name</div>
        <div className={styles.label}>Last QC</div>
      </div>

      <div className={styles.form}>
        <input type="text" placeholder="Enter Your Comment" />
      </div>

      <div className={styles.buttonSection}>
        <button className={styles.secondary}>Cancel</button>
        <button className={styles.secondary}>View Feedbacks</button>
        <button className={styles.primary}>Assign Job</button>
      </div>
    </div>
  );
}

export default FileAssignerForm;
