import React from "react";
import styles from "./styles.module.scss";
import DesignLeadDashboard from "../../Templates/dashboard/DesignLeadDashboard";
import DesignLeadToolbar from "../../Molecules/header/toolbar/DesignLeadToolbar";
import DesignLeadCompleted from "../../Molecules/widgets/DesignLeadCompleted";
import DesignLeadAction from "../../Molecules/widgets/DesignLeadAction";
import DesignStatistics from "../../Molecules/widgets/production_status/DesignStatistics";
import IQAStatistics from "../../Molecules/widgets/production_status/IQAStatistics";
import SQTStatistics from "../../Molecules/widgets/production_status/SQTStatistics";
import EQAStatistics from "../../Molecules/widgets/production_status/EQAStatistics";
import CommonHeader from "../../Molecules/header/commonheader/CommonHeader";
import DesignLeadNavbar from "../../Molecules/navbar/DesignLeadNavbar";

function HomePage() {
  return (
    <DesignLeadDashboard navbar={<DesignLeadNavbar />}>
      <CommonHeader />
      <DesignLeadToolbar />

      <div className={styles.firstHeader}>
        <DesignLeadCompleted />
        <DesignLeadAction />
      </div>

      <div className={styles.equalColumn}>
        <DesignStatistics />
        <IQAStatistics />
        <SQTStatistics />
        <EQAStatistics />
      </div>
    </DesignLeadDashboard>
  );
}

export default HomePage;
