import React from "react";
import styles from "./styles.module.scss";
import {
  ArticleOutlined,
  CasesOutlined,
  HomeOutlined,
  PeopleAltOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import NavButton from "../../Atoms/navbutton/NavButton";
function DesignLeadNavbar() {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/navbar_logo.webp"
          alt=""
        />
      </div>
      <ul>
        <NavButton
          backgroundColor="white"
          fontColor="#5B4A74"
          fontFamily="Urbanist"
          icon={<HomeOutlined />}
          fontSize="14px"
          gap="24px"
        >
          Home
        </NavButton>

        <NavButton
          backgroundColor="white"
          fontColor="#5B4A74"
          fontFamily="Urbanist"
          icon={<CasesOutlined />}
          fontSize="14px"
          gap="24px"
        >
          Jobs
        </NavButton>

        <NavButton
          backgroundColor="white"
          fontColor="#5B4A74"
          fontFamily="Urbanist"
          icon={<PeopleAltOutlined />}
          fontSize="14px"
          gap="24px"
        >
          Artists
        </NavButton>
        <NavButton
          backgroundColor="white"
          fontColor="#5B4A74"
          fontFamily="Urbanist"
          icon={<ArticleOutlined />}
          fontSize="14px"
          gap="24px"
        >
          Reports
        </NavButton>

        <NavButton
          backgroundColor="white"
          fontColor="#5B4A74"
          fontFamily="Urbanist"
          icon={<SettingsOutlined />}
          fontSize="14px"
          gap="24px"
        >
          Settings
        </NavButton>
      </ul>
    </div>
  );
}

export default DesignLeadNavbar;
