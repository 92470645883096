import React from 'react'
import styles from './TopGainers.module.scss'

const TopGainers = () => {
  return (
    <div className={styles.container}>
         
         <h2 className={styles.heading}>Top Gainers</h2>
         <div className={styles.bodySection}>
         <div className={styles.section1}>
          <div>
         <img src='/advertflair.png' alt='profile' className={styles.profile}/>
         <h3 className={styles.artistName}>Mathew Hoger</h3>
         <p className={styles.description}>India</p>
         </div>
         <div>
          <p className={styles.description}>Points</p>
          <h1 className={styles.points}>245</h1>
          <h4 className={styles.jobs}>14 Jobs</h4>
         </div>

         </div>
         <div className={styles.section2}>
         <div>
         <img src='/advertflair.png' alt='profile' className={styles.profile}/>
         <h3 className={styles.artistName}>Mathew Hoger</h3>
         <p className={styles.description}>India</p>
         </div>
         <div>
          <p className={styles.description}>Points</p>
          <h1 className={styles.points}>245</h1>
          <h4 className={styles.jobs}>14 Jobs</h4>
         </div>
         </div>
         <div className={styles.section3}>
         <div>
         <img src='/advertflair.png' alt='profile' className={styles.profile}/>
         <h3 className={styles.artistName}>Mathew Hoger</h3>
         <p className={styles.description}>India</p>
         </div>
         <div>
          <p className={styles.description}>Points</p>
          <h1 className={styles.points}>345</h1>
          <h4 className={styles.jobs}>14 Jobs</h4>
         </div>
         </div>
         </div>

    </div>
  )
}

export default TopGainers
