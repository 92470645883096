import React, { useState } from "react";
import styles from "./../Reports.module.scss";
import CommonTemplate from "../../../Templates/dashboard/CommonTemplate";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

function EqapdfReport() {
  const { register, handleSubmit } = useForm();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setSubmitting(true);
    const { startDate, endDate } = data;
    try {
      const response = await axios.get(
        `https://www.api.advflr.com/studio/report/eqa/download-pdf-report.php?startDate=${startDate}&endDate=${endDate}`,
        data,
        {
          responseType: "blob", // Set the response type to 'blob'
        }
      );

      // Handle successful response
      if (!response.data.error) {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(response.data);
        link.setAttribute("download", "EQA_Certification_Report.pdf");

        // Append the link to the document body
        document.body.appendChild(link);

        // Click the link to initiate the download
        link.click();

        // Remove the temporary link element
        document.body.removeChild(link);

        toast.success("Thank you, Your report has been downloaded");
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CommonTemplate>
      <div className={styles.container}>
        <h1>EQA PDF Generator</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.section}>
            <label htmlFor="">Start Date</label>
            <input type="date" {...register("startDate")} />
          </div>

          <div className={styles.section}>
            <label htmlFor="">End Date</label>
            <input type="date" {...register("endDate")} />
          </div>

          <div className={styles.btnSection}>
            <button className={styles.secondary}>Cancel</button>
            <button
              className={styles.primary}
              type="submit"
              disabled={submitting}
            >
              {submitting ? "Please Wait" : "Download report"}
            </button>
          </div>
        </form>
      </div>
      <Toaster />
    </CommonTemplate>
  );
}

export default EqapdfReport;
