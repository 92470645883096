import React from "react";
import styles from "./styles.module.scss";
import Text from "../../Atoms/text/Text";
import ProgressBar from "../../Atoms/progressbar/ProgressBar";
import IconButton from "../../Atoms/iconbutton/IconButton";
import { ArrowForward } from "@mui/icons-material";

function CompletedProject() {
  return (
    <div className={styles.container}>
      <Text
        fontSize="22px"
        fontFamily="Urbanist"
        color="white"
        fontWeight="700"
      >
        Completed Projects
      </Text>

      <ProgressBar bgColor="#EDE8F0" progressColor="#1ED285" value="87" />
      <Text color="#B8B4D2" fontSize="12px">
        16 Projects yet to complete
      </Text>

      <IconButton
        backgroundColor="transparent"
        fontColor="white"
        fontWeight="700"
        border="1px solid #AE7CE7"
        icon={<ArrowForward />}
      >
        View all Projects
      </IconButton>
    </div>
  );
}

export default CompletedProject;
