import React from "react";
import CommonTemplate from "../../../../Templates/dashboard/CommonTemplate";
import ReassignTable from "../../../../Molecules/table/ReassignTable";

function ReassignJobsList() {
  return (
    <>
      <CommonTemplate>
        <ReassignTable />
      </CommonTemplate>
    </>
  );
}

export default ReassignJobsList;
