import React from "react";
import styles from "./styles.module.scss";
import Text from "../../../Atoms/text/Text";
import Button from "../../../Atoms/button/Button";
import IconButton from "../../../Atoms/iconbutton/IconButton";
import { Add } from "@mui/icons-material";

function DesignLeadToolbar() {
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div>
          <Text fontSize="16px" color="#7C6E90">
            Welcome, Lead
          </Text>
        </div>
        <div>
          <Text
            fontFamily="Urbanist"
            fontSize="29px"
            color="#311057"
            fontWeight="700"
          >
            Dashboard Overview
          </Text>
        </div>
      </div>

      <div className={styles.rightSection}>
        <Button
          backgroundColor="#311057"
          width="72px"
          height="40px"
          fontColor="white"
          fontWeight="500"
        >
          Today
        </Button>

        <Button
          backgroundColor="white"
          width="72px"
          height="40px"
          fontColor="#311057"
          fontWeight="500"
        >
          Week
        </Button>
        <Button
          backgroundColor="white"
          width="72px"
          height="40px"
          fontColor="#311057"
          fontWeight="500"
        >
          Month
        </Button>

        <IconButton
          backgroundImage="linear-gradient(25deg,orange,red)"
          fontColor="white"
          fontWeight="700"
          icon={<Add />}
        >
          Assign Job
        </IconButton>
      </div>
    </div>
  );
}

export default DesignLeadToolbar;
