import React from 'react'
import styles from './JobBoard.module.scss'
import ArtistNavbar from '../navbar/ArtistNavbar'
import Common from '../header/Common'
// import JobDetails from '../jobDetails/JobDetail'
import JobDetail from '../jobDetails/JobDetail'

const JobBoard = () => {
  return (
    <div className={styles.container}>
         <div className={styles.navSection}>
        <ArtistNavbar />
      </div>

      <div className={styles.bodySection}>
        <div className={styles.section1}>
          <Common />
        </div>
        <div className={styles.section2}>
        <JobDetail/>
    </div>
    </div>
   
    </div>
  )
}

export default JobBoard