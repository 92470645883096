import React from "react";
import styles from "./ProgressBar.module.scss";
function ProgressBar({ value, bgColor, progressColor, borderRadius }) {
  const containerStyle = {
    backgroundColor: bgColor,
    borderRadius: borderRadius,
  };
  const fillerStyles = {
    width: `${value}%`,
    backgroundColor: progressColor,
    borderRadius: borderRadius,
  };
  return (
    <div className={styles["progress-bar"]} style={containerStyle}>
      <div className={styles["progress-fill"]} style={fillerStyles}>
        {value}%
      </div>
    </div>
  );
}

export default ProgressBar;
