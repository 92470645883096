import React from 'react'
import styles from './QuerySupport.module.scss'
import QuerySupportContainer from './QuerySupportContainer'

const QuerySupport = () => {
  return (
    <div className={styles.container}>
         <div className={styles.section2}>
          <h1 className={styles.name}>Support</h1>
          <p className={styles.description}>Lorem ipsum dolor</p>
        </div>
        <div>
            <QuerySupportContainer/>
        </div>
    </div>
  )
}

export default QuerySupport