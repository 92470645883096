import React from "react";
import styles from "./NavButton.module.scss";

function NavButton({
  children,
  icon,
  borderRadius,
  width,
  height,
  gap,
  fontColor,
  fontWeight,
  fontSize,
  fontFamily,
  onClick,
}) {
  const inlineStyle = {
    borderRadius,
    width,
    height,
    gap,
    color: fontColor,
    fontSize,
    fontWeight,
    fontFamily,
  };
  return (
    <button className={styles.navbutton} style={inlineStyle} onClick={onClick}>
      {icon && <span>{icon}</span>}
      {children}
    </button>
  );
}

export default NavButton;
