import React from "react";
import styles from "./ArtistGreet.module.scss";

function ArtistGreet() {
  return (
    <div className={styles.container}>
      <div className={styles.greetSection}>
        <p className={styles.subtitle}>Welcome Ping</p>
        <p className={styles.title}>Job board Overview</p>
      </div>

      <div className={styles.filterSection}>
        <select name="" id="">
          <option selected>Select Week</option>
          <option value="">WK-20-2024</option>
          <option value="">WK-21-2024</option>
          <option value="">WK-22-2024</option>
        </select>

        <select name="" id="">
          <option selected>Select Month</option>
          <option value="">January</option>
          <option value="">Feburary</option>
          <option value="">March</option>
        </select>
      </div>
    </div>
  );
}

export default ArtistGreet;
