import React from "react";
import styles from "./styles.module.scss";
import Text from "../../../Atoms/text/Text";
import IconButton from "../../../Atoms/iconbutton/IconButton";
import {
  ArrowForward,
  CloudOutlined,
  ContactSupportOutlined,
  HourglassDisabledOutlined,
  QueryBuilderOutlined,
} from "@mui/icons-material";
import Statistics from "../../../Atoms/statistics/Statistics";

function IQAStatistics() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.column}>
          <Text
            fontSize="22px"
            fontFamily="Urbanist"
            fontWeight="700"
            color="#311057"
          >
            IQA Status
          </Text>
          <Text fontSize="12px" color="#7C6E90">
            Total 14 files got approved in IQA
          </Text>
        </div>

        <IconButton icon={<ArrowForward />}>View All</IconButton>
      </div>
      <div className={styles.body}>
        <Statistics icon={<CloudOutlined />} label="YTS" value="12" />
        <Statistics icon={<QueryBuilderOutlined />} label="WIP" value="4" />
        <Statistics
          icon={<HourglassDisabledOutlined />}
          label="Park"
          value="3"
        />
        <Statistics
          icon={<ContactSupportOutlined />}
          label="Clarification"
          value="5"
        />
      </div>
    </div>
  );
}

export default IQAStatistics;
