import React, { useEffect, useState } from "react";

import { teamDetails } from "../../assets/itTeam";
import axios from "axios";

function ItTeam() {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const response = await axios.get(
      "https://www.api.advflr.com/studio/it-team/team-info.php"
    );
    setData(response.data);
    console.log(data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="w-full h-screen bg-slate-200">
      <div className="flex flex-col gap-4 justify-center items-center">
        <div className="px-[6px] py-[2px] rounded-sm bg-slate-400 mt-8 font-bold text-slate-50 cursor-pointer">
          IT-Team
        </div>
        <div className="text-3xl font-bold">Our Amazing Team</div>
        <div className="text-md">
          Welcome to the team! We are a group of passionate individuals working
          together to achieve our goals. Get to know the faces behind our
          success.
        </div>

        <div className="grid grid-cols-4 gap-2 w-[80%]">
          {data.map((users) => (
            <div className="flex flex-col gap-1 justify-center items-center bg-slate-200 border-[1px] cursor-pointer ">
              <img
                className="w-[280px] h-[280px] object-cover rounded-md shadow  hover:shadow-xl"
                src={users.user_pic}
                alt={users.name}
              />
              <p className="text-lg font-bold">{users.name}</p>
              <p className="text-[10px] font-bold px-2 py-[4px] bg-slate-600 rounded-sm text-white">
                {users.designation}
              </p>

              <p className="text-sm font-light">{users.description}</p>
              <div className="flex gap-2">
                <div className="border-[1px] px-4 py-[2px] text-[10px]  border-slate-700">
                  Done : {users.completed_tasks}
                </div>
                <div className="border-[1px] px-4 py-[2px] text-[10px]  border-slate-700">
                  Pending : {users.wip_tasks}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ItTeam;
