import React from "react";
import styles from "./styles.module.scss";
import Text from "../../../Atoms/text/Text";
import EmployeeWithPicture from "../../../Atoms/Labels/employeewithpicture/EmployeeWithPicture";
import IconButton from "../../../Atoms/iconbutton/IconButton";
import { ChevronRight } from "@mui/icons-material";
function LeaveApprovals() {
  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <Text fontWeight="700" fontSize="24px">
          Pending Approval
        </Text>

        <Text fontSize="18px" fontWeight="600" color="red">
          6 Request
        </Text>
      </div>

      <div className={styles.bodySection}>
        <div className={styles.rowMethod}>
          <EmployeeWithPicture
            name="Immanuel"
            image="https://3dstudio.advertflair.com/common/assets/images/users/immangeek.webp"
          />
          <IconButton icon={<ChevronRight />}> Review Leave</IconButton>
        </div>
        <div className={styles.rowMethod}>
          <EmployeeWithPicture
            name="Sathish Kumar"
            image="https://3dstudio.advertflair.com/common/assets/images/users/sathish18.webp"
          />
          <IconButton icon={<ChevronRight />}> Review Leave</IconButton>
        </div>

        <div className={styles.rowMethod}>
          <EmployeeWithPicture
            name="Sarnya R"
            image="https://3dstudio.advertflair.com/common/assets/images/users/saranya_r.webp"
          />
          <IconButton icon={<ChevronRight />}> Review Leave</IconButton>
        </div>
      </div>
    </div>
  );
}

export default LeaveApprovals;
