import React from "react";
import styles from "./styles.module.scss";

function DesignLeadDashboard({ children, navbar }) {
  return (
    <div className={styles.container}>
      <div>{navbar}</div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default DesignLeadDashboard;
