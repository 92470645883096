import React from "react";
import styles from "./styles.module.scss";
import IconButton from "../../../Atoms/iconbutton/IconButton";
import { AddOutlined, BackupOutlined } from "@mui/icons-material";
import EmployeeWithPicture from "../../../Atoms/Labels/employeewithpicture/EmployeeWithPicture";

function EmployeeTable() {
  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <div>
          <input type="text" placeholder="Search Employees" />
        </div>

        <div className={styles.buttonSection}>
          <IconButton icon={<BackupOutlined />}> Import CSV</IconButton>
          <IconButton icon={<AddOutlined />}> Add Employee</IconButton>
        </div>
      </div>
      <div className={styles.tableSection}>
        <table>
          <thead>
            <tr>
              <td>No</td>
              <td>Name</td>
              <td>Designation</td>
              <td>Experience</td>
              <td>Status</td>
              <td>WIP Files</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>
                <EmployeeWithPicture
                  name="Immanuel A"
                  image="https://3dstudio.advertflair.com/common/assets/images/users/immangeek.webp"
                />
              </td>
              <td>3D Excecutive Artist</td>
              <td>24 Months</td>
              <td>Active</td>
              <td>3</td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                <EmployeeWithPicture
                  name="Sarnya R"
                  image="https://3dstudio.advertflair.com/common/assets/images/users/saranya_r.webp"
                />
              </td>
              <td>3D Senior Artist</td>
              <td>36 Months</td>
              <td>Inactive</td>
              <td>5</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EmployeeTable;
