import React from 'react'

import Common from '../header/Common'
import styles from './AssignNewJob.module.scss' 
import ArtistNavbar from './ArtistNavbar'
// import AllArtists from '../leaderboard/AllArtists'
import CommonCheckbox from '../common/CommonCheckbox'


const AssignNewJob = () => {
  return (
  
    <div className={styles.container}>
    <div className={styles.navSection}>
   <ArtistNavbar />
 </div>

 <div className={styles.bodySection}>
   <div className={styles.section1}>
     <Common />
   </div>
   <div className={styles.section2}>
   <CommonCheckbox/>
</div>
<div className={styles.section3}>
  {/* <ArtistList/> */}
</div>
<div className={styles.footer}>
   <p>Copyrights 2024 @Advertflair Pvt Ltd</p>
</div>
</div>
</div>
  )
}

export default AssignNewJob