import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Text from "../../Atoms/text/Text";
import Cookies from "js-cookie";
import moment from "moment";
import "moment-timezone";
import FileAssignModalForm from "../modal-forms/FileAssignModalForm";

function YtaTable() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState([]);
  const [poid, setPoid] = useState("");
  const [dataloaded, setDataloaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const setCookie = (username, cookieTitle) => {
    const expirationTime = moment().tz("Asia/Kolkata").endOf("day");
    Cookies.set(cookieTitle, username, { expires: expirationTime.toDate() });
  };

  const handleFormSubmit = () => {
    fetchData();
  };

  const handleModalOpen = (poid) => {
    setPoid(poid);
    setCookie(poid, "poid");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://www.api.advflr.com/studio/common/jobs.php?final_status=YTA"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      if (jsonData.error) {
        console.log("Error in json");
      } else {
        setData(jsonData);
        setCount(jsonData.length);
        setDataloaded(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      <Text
        fontFamily="Urbanist"
        fontSize="22px"
        color="#311057"
        fontWeight="700"
      >
        {dataloaded ? (
          <>YTA Jobs - {count}</>
        ) : (
          <>There is no jobs at the moment</>
        )}
      </Text>

      <div className={styles.line}></div>
      {dataloaded && (
        <table>
          <thead>
            <td>POID</td>
            <td>Workflow</td>
            <td>Process</td>
            <td>Lot</td>
            <td>Level</td>
            <td>Team</td>
            <td>Actions</td>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  <a href={item.model_link} rel="noreferrer" target="_blank">
                    {item.partner_id}
                  </a>
                </td>
                <td>{item.workflow}</td>
                <td>{item.process}</td>
                <td>{item.Lot}</td>
                <td>{item.file_level}</td>
                <td>{item.team}</td>
                <td>
                  <button
                    className={styles.primary}
                    onClick={() => handleModalOpen(item.partner_id)}
                  >
                    Assign Job
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <FileAssignModalForm
        poid={poid}
        isOpen={modalOpen}
        onClose={handleModalClose}
        onSubmit={handleFormSubmit}
      />
    </div>
  );
}

export default YtaTable;
