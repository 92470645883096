import React, { useEffect, useState } from "react";
import styles from "./ListControlMolecule.module.scss";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function ListJobsControlMolecule() {
  const navigate = useNavigate();

  const [stats, setStats] = useState({
    cc_files: ".",
    revision_cc_files: ".",
    yta_files: ".",
    yts_files: ".",
    wip_files: ".",
    clarification_files: ".",
    hold_files: ".",
    iqa_files: ".",
    iqa_wip_files: ".",
    iqa_park_files: ".",
    sqt_files: ".",
    eqa_files: ".",
  });

  const [permissions, setPermissions] = useState([]);

  const permissionError = () => {
    alert("You don't have permission to access this page");
  };

  const fetchPermissions = async () => {
    const username = Cookies.get("emp_username");
    try {
      const response = await fetch(
        `https://www.api.advflr.com/studio/permissions/jobs.php?artist=${username}`
      );
      const data = await response.json();
      setPermissions(data[0]);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const fetchStats = async () => {
    try {
      const response = await fetch(
        "https://www.api.advflr.com/studio/design_leads/stats/pending-jobs.php"
      );
      const data = await response.json();
      setStats(data[0]);
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  useEffect(() => {
    fetchPermissions();
    fetchStats();
  }, []);

  const handleRevisioncc = () => {
    navigate("/common/list-jobs/revision-cc");
  };

  const handleYTA = () => {
    navigate("/common/list-jobs/yta");
  };

  const handleHold = () => {
    navigate("/common/list-jobs/hold");
  };

  const handleCc = () => {
    navigate("/common/list-jobs/cc");
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleSection}>
        <h1>Overall Jobs Control System</h1>
        <p>Here you can control all the access</p>
      </div>

      <div className={styles.listSection}>
        <div
          className={
            permissions.cc_permission ? styles.list : styles.listDisabled
          }
          onClick={permissions.cc_permission ? handleCc : permissionError}
        >
          <div className={styles.iconSection}>{stats.cc_files}</div>

          <div className={styles.textSection}>
            <h1>Compliance Check</h1>
            <p>Fresh files from client </p>
          </div>
        </div>

        <div
          className={
            permissions.revisioncc_permission
              ? styles.list
              : styles.listDisabled
          }
          onClick={
            permissions.revisioncc_permission
              ? handleRevisioncc
              : permissionError
          }
        >
          <div className={styles.iconSection}>{stats.revision_cc_files}</div>

          <div className={styles.textSection}>
            <h1>Revision CCheck</h1>
            <p>Files got rejected from internal or external</p>
          </div>
        </div>

        <div
          className={
            permissions.assign_permission ? styles.list : styles.listDisabled
          }
          onClick={permissions.assign_permission ? handleYTA : permissionError}
        >
          <div className={styles.iconSection}>{stats.yta_files}</div>

          <div className={styles.textSection}>
            <h1>YTA Jobs</h1>
            <p>Overall Yet to Assign jobs</p>
          </div>
        </div>

        <div
          className={
            permissions.reassign_permission ? styles.list : styles.listDisabled
          }
          onClick={
            permissions.reassign_permission ? handleHold : permissionError
          }
        >
          <div className={styles.iconSection}>{stats.hold_files}</div>

          <div className={styles.textSection}>
            <h1>Reassign Jobs</h1>
            <p>Here you can reassign Jobs</p>
          </div>
        </div>

        <div
          className={
            permissions.clarificationUpdate_permission
              ? styles.list
              : styles.listDisabled
          }
        >
          <div className={styles.iconSection}>{stats.clarification_files}</div>

          <div className={styles.textSection}>
            <h1>Clarification Updater</h1>
            <p>Jobs are in Clarification by Team Leads</p>
          </div>
        </div>

        <div
          className={
            permissions.iqaassign_permission ? styles.list : styles.listDisabled
          }
        >
          <div className={styles.iconSection}>{stats.iqa_files}</div>

          <div className={styles.textSection}>
            <h1>IQA Jobs</h1>
            <p>Jobs Currently in IQA Queue</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListJobsControlMolecule;
