import React from "react";
import CommonTemplate from "../../../../Templates/dashboard/CommonTemplate";
import RevisionCCTable from "../../../../Molecules/table/RevisionCCTable";

function RevisionCCjobsList() {
  return (
    <>
      <CommonTemplate>
        <RevisionCCTable />
      </CommonTemplate>
    </>
  );
}

export default RevisionCCjobsList;
