import React from 'react'
import styles from './UploadHeader.module.scss'
import DragDrop from './DragDrop'

const UploadHeader = () => {
  return (
    <>
    <div className={styles.container}>
       <div className={styles.section1}>
        <h1 className={styles.heading}>File Uploader</h1>
        <p className={styles.description}>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
       </div>
       <div className={styles.section2}>
        <img src='./assets/icons/orangeArrow.png' alt="orange"/>
      
         <p className={styles.title}>Start Time:</p>
         {/* <input type="date"  /> */}
         <img src="./assets/icons/calendar.png" alt="calendar" />
         <p className={styles.title}>15 Jan 2023 </p>
         <img src="./assets/icons/clock.png" alt="clock" />
         <p className={styles.title}>01:23AM</p>
         
       </div> 
       <div className={styles.section3}>
      
        <img src="./assets/icons/greenArrow.png" alt="green" />
        <p className={styles.title}>Duration</p>
        <img src="./assets/icons/clock.png" alt="clock" />
        <p className={styles.time}>30 mins</p>
       </div>

    </div>
    <div>
      <DragDrop/>
    </div>
    </>
  )
}

export default UploadHeader