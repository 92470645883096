import React from "react";
import Statistics from "../../../Atoms/statistics/Statistics";
import styles from "./styles.module.scss";
import {
  AccountCircleOutlined,
  DesktopAccessDisabledOutlined,
  HourglassDisabledOutlined,
  NoAccountsOutlined,
  PeopleOutline,
  WatchLaterOutlined,
} from "@mui/icons-material";

function EmployeeStatus() {
  return (
    <div className={styles.container}>
      <Statistics label="Total Employees" value="24" icon={<PeopleOutline />} />
      <Statistics
        label="Active Employees"
        value="17"
        icon={<AccountCircleOutlined />}
      />
      <Statistics label="On Leave" value="17" icon={<NoAccountsOutlined />} />
      <Statistics label="Late Logins" value="4" icon={<WatchLaterOutlined />} />
      <Statistics
        label="Idle Artists"
        value="4"
        icon={<DesktopAccessDisabledOutlined />}
      />

      <Statistics
        label="Break Exceders"
        value="4"
        icon={<HourglassDisabledOutlined />}
      />
    </div>
  );
}

export default EmployeeStatus;
