import React from "react";
import styles from "./styles.module.scss";
import Text from "../../Atoms/text/Text";
import Button from "../../Atoms/button/Button";

function IqaWipTable() {
  return (
    <div className={styles.container}>
      <Text
        fontFamily="Urbanist"
        fontSize="22px"
        color="#311057"
        fontWeight="700"
      >
        Assigned Project
      </Text>

      <div className={styles.line}></div>

      <table>
        <thead>
          <td>POID</td>
          <td>ASIN</td>
          <td>Category</td>
          <td>Type</td>
          <td>Duration</td>
          <td>Started At</td>
          <td>Status</td>
        </thead>
        <tbody>
          <tr>
            <td>TESTING_2</td>
            <td>PJIKJKSDF</td>
            <td>Laptop</td>
            <td>Recharter</td>
            <td>30 Minutes</td>
            <td>9:00 AM</td>
            <td>
              <Button
                backgroundColor="orange"
                fontColor="white"
                fontWeight="700"
                width="96px"
                height="24px"
              >
                In-Progress
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default IqaWipTable;
