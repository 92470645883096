import React from "react";
import styles from "./Navmenu.module.scss";

function Navmenu({ icon, label }) {
  const defaulticon = icon || "/assets/icons/supporticon.png";
  const defaultlabel = label || "";
  return (
    <div className={styles.container}>
      <img src={defaulticon} alt="Icon" />
      <p>{defaultlabel}</p>
    </div>
  );
}

export default Navmenu;
