import React from 'react'
import styles from './LeaderBoard.module.scss'
import ArtistNavbar from '../navbar/ArtistNavbar'
import Common from '../header/Common'
// import TopGainers from './TopGainers'
import AllArtists from './AllArtists'
import ArtistList from './ArtistList'

const LeaderBoard = () => {
  return (
    <div className={styles.container}>
    <div className={styles.navSection}>
   <ArtistNavbar />
 </div>

 <div className={styles.bodySection}>
   <div className={styles.section1}>
     <Common />
   </div>
   <div className={styles.section2}>
   <AllArtists/>
</div>
<div className={styles.section3}>
  <ArtistList/>
</div>
<div className={styles.footer}>
   <p>Copyrights 2024 @Advertflair Pvt Ltd</p>
</div>
</div>


</div>
  )
}

export default LeaderBoard