import React from "react";
import styles from "./styles.module.scss";
import IconButton from "../../../Atoms/iconbutton/IconButton";
import { BackupOutlined } from "@mui/icons-material";
import EmployeeWithPicture from "../../../Atoms/Labels/employeewithpicture/EmployeeWithPicture";

function LeaderBoard() {
  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <div>
          <input type="text" placeholder="Search Employees" />
        </div>

        <div className={styles.buttonSection}>
          <select name="" id="">
            <option value="" selected disabled>
              Select Month
            </option>
            <option value="">January</option>
            <option value=""> Feburary</option>
            <option value=""> March</option>
          </select>
          <IconButton icon={<BackupOutlined />}> Export CSV</IconButton>
        </div>
      </div>
      <div className={styles.tableSection}>
        <table>
          <thead>
            <tr>
              <td>Rank</td>
              <td>Emp ID</td>
              <td>Name</td>
              <td>Team</td>
              <td>Approvals</td>
              <td>Points</td>
              <td>Rework Avg</td>
              <td>EQA</td>
              <td>Finalized Points</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>3D034</td>

              <td>
                <EmployeeWithPicture
                  name="Saranya R"
                  image="https://3dstudio.advertflair.com/common/assets/images/users/saranya_r.webp"
                />
              </td>
              <td>FOX</td>
              <td>9</td>
              <td>180</td>
              <td>1.1</td>
              <td>89%</td>
              <td>230</td>
            </tr>
            <tr>
              <td>2</td>
              <td>3D033</td>
              <td>
                <EmployeeWithPicture
                  name="Sri Ram"
                  image="https://3dstudio.advertflair.com/common/assets/images/users/immangeek.webp"
                />
              </td>
              <td>DOT</td>

              <td>5</td>
              <td>140</td>
              <td>1.2</td>
              <td>80%</td>
              <td>190</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LeaderBoard;
