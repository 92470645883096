import React from "react";
import styles from "../Graphs.module.scss";

function ApprovalGraph() {
  return (
    <div className={styles.container}>
      <div className={styles.headingSection}>
        <div className={styles.titleSection}>
            <div className={styles.title}></div>
        </div>
      </div>
    </div>
  );
}

export default ApprovalGraph;
