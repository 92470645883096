import React from "react";
import styles from "./styles.module.scss";

function EmployeeWithPicture({ image, name }) {
  return (
    <div className={styles.container}>
      <img src={image} alt="Profile" />
      <p>{name}</p>
    </div>
  );
}

export default EmployeeWithPicture;
