import React, { useState } from 'react';
import styles from './CheckStatus.module.scss';

const CheckStatus = () => {
  const [selectedFile, setSelectedFile] = useState('');

  return (
    <div className={styles.container}>
      <div className={styles.mainSection}>
        <input type="text" placeholder='Enter POID' />
        <button className={styles.startButton}>Check Status</button>
      </div>
      <div className={styles.section2}>
        <p>Files:</p>
        <div className={styles.section}>
          <div className={styles.section1}>
            <input
              type="radio"
              id="freshFiles"
              name="fileStatus"
              value="Fresh files"
              checked={selectedFile === 'Fresh files'}
              onChange={() => setSelectedFile('Fresh files')}
            />
            <label htmlFor="freshFiles">Fresh File</label>
          </div>
          <div className={styles.section2}>
            <input
              type="radio"
              id="assignedAlready"
              name="fileStatus"
              value="Assigned already"
              checked={selectedFile === 'Assigned already'}
              onChange={() => setSelectedFile('Assigned already')}
            />
            <label htmlFor="assignedAlready">Already Assigned</label>
          </div>
        </div>
      </div>
      <div className={styles.section3}>
        <button className={styles.secondaryButton}>Reference</button>
        <button className={styles.secondaryButton}>IQA</button>
        <button className={styles.secondaryButton}>SQT</button>
        <button className={styles.secondaryButton}>EQA</button>
      </div>
      <div className={styles.section4}>
        <p>Current Status:</p>
        <select name="" id="">
          <option value="">Yet to Start</option>
          <option value="">WIP</option>
          <option value="">Hold</option>
          <option value="">Completed</option>
        </select>
      </div>
      <div className={styles.section5}>
        <p>Artist Name:</p>
        <div className={styles.selectSection}>
          <select name="" id="">
            <option value="">--Choose Name--</option>
            <option value="">Yahini</option>
            <option value="">Immanuel</option>
            <option value="">Boobalan</option>
          </select>
          <button className={styles.secondaryButton}>Assign</button>
        </div>
      </div>
      <div className={styles.section6}>
        <textarea name="" id="" placeholder="Enter clarification details"></textarea>
        <button className={styles.secondaryButton}>Clarification</button>
      </div>
    </div>
  );
}

export default CheckStatus;
