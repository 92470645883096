import React, { useEffect, useState } from "react";
import styles from "./LoginPage.module.scss";
import moment from "moment";
import "moment-timezone";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useAuthstore from "../../store";

function LoginPage() {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      empID: "3D",
      password: "",
    },
  });

  const { setUser } = useAuthstore();
  const { errors } = formState;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const empId = Cookies.get("emp_id");

    if (empId) {
      navigate("/home");
    }
  }, [navigate]);

  const setCookie = (username, cookieTitle) => {
    const expirationTime = moment().tz("Asia/Kolkata").endOf("day");
    Cookies.set(cookieTitle, username, { expires: expirationTime.toDate() });
  };

  async function onSubmit(data) {
    setIsSubmitting(true);
    const { empID, password } = data;

    try {
      const response = await fetch(
        "https://www.api.advflr.com/studio/common/auth/login.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: empID, password }),
        }
      );

      const data = await response.json();
      console.log(data.error);
      if (!data.error) {
        // Handle successful login
        setCookie(data.emp_id, "emp_id");
        setCookie(data.emp_username, "emp_username");
        setCookie(data.emp_team, "emp_team");
        setCookie(data.emp_pic, "emp_pic");
        setCookie(data.emp_name, "emp_name");
        navigate("/home");
        setUser(data);

        //Storing COokie Values
      } else {
        // Handle login error
        const errorContainer = document.getElementById("passwordError");
        errorContainer.textContent = data.error;
        //alert(data.error);
      }
    } catch (error) {
      //alert(error);
      const errorContainer = document.getElementById("passwordError");
      errorContainer.textContent = error;
      //console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.loginbox}>
          <div>
            <label htmlFor="login">Employee ID</label>
            <input
              {...register("empID", { required: "Employee ID is required" })}
            />
            <p>{errors.empID?.message}</p>
          </div>
          <div>
            <label htmlFor="login">Password</label>
            <input
              type="password"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password Needs Minimum length of 6",
                },
              })}
            />
            <p id="passwordError">{errors.password?.message}</p>
          </div>
          <div className={styles.buttonSection}>
            <button
              type="submit"
              className={styles.primary}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Please Wait" : "Login to POD"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default LoginPage;
