import React from 'react';
import styles from './JobDetails.module.scss';
import ProductDetails from '../productdetails/ProductDetails';

const JobDetail = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.imageSection}>
            <img src='assets/icons/backArrow.png' alt='arrow-left' />
          </div>
          <div className={styles.section1}>
            <h1 className={styles.jobDetail}>AD112211 - Job Details</h1>
            <div className={styles.section2}>
              <div className={styles.active}></div>
              <p className={styles.description}>Yet to Start</p>
            </div>
          </div>
        </div>
        <div className={styles.actionSection}>
          <button className={styles.startButton}>Start Job</button>
          <div className={styles.kebabIcon}>&#x22EE;</div>
        </div>
       
      </div>

      <div>
          
           <ProductDetails/>
           
      </div>

      <div className={styles.footer}>
          <p>Copyrights 2024 @Advertflair Pvt Ltd</p>
        </div>
    </>
  );
};

export default JobDetail;
